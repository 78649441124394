import { isAuditNote } from 'helpers/audit'
import { htmlAdapter } from 'lib/remirror/util/froala.adapter'
import { htmlToRemirrorJSON } from 'lib/remirror/util/remirror.util'
import { isEmpty, isObject } from 'lodash'
import { orderBy, pick, uniqueId } from 'lodash-es'
import { observable } from 'mobx'
import moment from 'moment'
import { trackEvent } from '../helpers/posthog'
import { captureExceptionSilently } from '../helpers/sentry'
import { msg } from './msg'
import noteService from './services/note.service'
import scratchService from './services/scratch.service'
import Str from './util/str.util'

const NoteInputFields = [
  'id',
  'advisorId',
  'appointmentId',
  'clientId',
  'text',
  'content',
  'due',
  'status',
  'isAgenda',
  'isSummary',
  'isPrivate',
  'order',
  'tags',
  'topic',
  'file1',
  'file2',
  'file3',
  'task',
  'workflow',
  'templateIncludedId',
]

class Notes {
  /**
   * Load all files for a given s3 path
   */
  addNote = async (note) => {
    const payload = { note: pick(note, NoteInputFields) }

    return noteService.createNote(payload)
  }

  bulkAddNote = async (notes, skipResponse) => {
    global.app.loading = `Adding ${notes.length} Notes! Working hard...`
    const payload = { notes: notes.map((note) => pick(note, NoteInputFields)) }

    try {
      return await noteService.bulkAddNotes(payload)
    } catch (error) {
      msg.error(['notes', 'adding'])
      captureExceptionSilently(error, { message: 'bulkAddNote', data: { payload } })
      throw error
    } finally {
      global.app.loading = false
    }
  }

  /**
   * Delete Note
   */
  deleteNote = async (note, tasks = [], workflows = [], deleteOnProvider = false) => {
    global.app.loading = 'Deleting...'
    const payload = {
      id: note.id,
      tasks: tasks.filter(Boolean),
      workflows: workflows.filter(Boolean),
      deleteOnProvider,
    }

    try {
      trackEvent('Delete Note', {
        task: payload.tasks.length,
        workflows: payload.workflows.length,
        deleteOnProvider,
        noteId: payload.id,
      })
      return await noteService.deleteNote(payload)
    } catch (error) {
      msg.error(['note', 'deleting'])
      captureExceptionSilently(error, { message: 'deleteNote', data: { payload } })
    } finally {
      global.app.loading = false
    }
  }

  updateNote = async (id, note) => {
    const payload = { id, note: pick(note, NoteInputFields) }
    try {
      await noteService.updateNote(payload)
    } catch (error) {
      msg.error(['note', 'updating'])
      captureExceptionSilently(error, { message: 'updateNote', data: { payload } })
      throw error
    }
  }

  updateNotesInBulk = async (notes) => {
    const payload = { notes: notes.map((note) => pick(note, NoteInputFields)) }
    try {
      const accepted = await noteService.updateNotesInBulk(payload)

      return accepted
    } catch (error) {
      msg.error(['notes', 'updating'])
      captureExceptionSilently(error, { message: 'updateNotesInBulk', data: { payload } })
    }
  }

  reorderNotes = async (apptId, order) => {
    const payload = { apptId, order }

    await noteService.reorderAppointmentNotes(payload)
  }

  transfer = async (model, items, apptId, clientId) => {
    if (!clientId) {
      clientId = this.getAppt(apptId).clientId
    }
    const payload = {
      [model]: (items || []).map((e) => (isNaN(e) ? e.id : e)),
      apptId,
      clientId,
    }

    try {
      trackEvent('Transfer ' + model, { itemsTransferred: items.length, clientId, apptId })

      if (model === 'notes') {
        return await noteService.transferNotes(payload)
      } else {
        return await scratchService.transferScratches(payload)
      }
    } catch (error) {
      msg.error([model, 'transferring'])
      captureExceptionSilently(error, { message: 'transfer', data: { payload } })
    }
  }

  orderNotesById(ids, notes) {
    return ids
      .map((id, index) => ({ ...notes.find((elem) => elem.id === id), order: index }))
      .filter((note) => !!note.id)
  }

  updateNoteListItemProps = (noteList, itemId, itemFields) => {
    const index = noteList.findIndex((o) => o.id === itemId)
    if (index !== -1) {
      const item = noteList[index]
      Object.keys(itemFields).forEach((key) => {
        item[key] = itemFields[key]
      })

      // if (itemFields.text) {
      //   item.hasFieldsToFill = smartfieldStore.hasSmartfield(itemFields.text)
      // }

      const modifiedList = [...noteList.slice(0, index), item, ...noteList.slice(index + 1)]
      if ('order' in itemFields || 'createdAt' in itemFields || 'appointmentId' in itemFields) {
        return orderBy(modifiedList, ['appointmentId', 'order', 'createdAt'], ['asc', 'asc', 'desc'])
      } else {
        return modifiedList
      }
    }
    return noteList
  }

  _getNewNoteOrder(noteList) {
    if (!Array.isArray(noteList)) {
      return 0
    }
    if (!noteList.length) {
      return 0
    }
    const lastNote = noteList[noteList.length - 1]
    if (lastNote.order >= noteList.length - 1) {
      return lastNote.order + 1
    }
    return noteList.length
  }

  prepareNote(topic, summary, directAppt, list, templateIncludedId) {
    const {
      clients,
      advisors: { me: advisor },
      appt: { appointment: loadedAppt },
    } = global.data

    const appt = directAppt || loadedAppt

    let content = summary.content
    if (isObject(summary.content)) {
      content = JSON.stringify(summary.content)
    } else if (isEmpty(summary.content)) {
      content = htmlToRemirrorJSON(summary.text)
    }

    const details = {
      isAgenda: !!summary.isAgenda,
      isSummary: !!summary.isSummary,
      isPrivate: !!summary.isPrivate,
      advisorId: advisor.id,
      clientId: directAppt ? directAppt.clientId : clients.clientId,
      appointmentId: appt.id,
      due: Number.isInteger(Number(topic.dueDays)) ? topic.dueDays : 5,
      topic: topic.name,
      file1: summary.file1,
      file2: summary.file2,
      file3: summary.file3,
      createdAt: moment(),
      status: 'Pending',
      unreadMessages: 0,
      totalMessages: 0,
      text: summary.text,
      content: content,
      order: this._getNewNoteOrder(list || global.data.appt.notes),
      tags: (summary.tags || []).map(({ __typename, updatedAt, ...tag }) => tag),
      templateIncludedId,
    }

    details.task = global.tasks.buildTask(details, summary.taskTemplate, false, appt)
    details.workflow = global.workflows.buildWorkflow(details, summary.workflowTemplate, false, appt)

    return { details, appt, advisor, summary, topic }
  }

  /**
   * @param {*} note
   */
  hydrateNotes = (notes, noteIdsIncluded = []) => {
    return observable(
      notes.filter(Boolean).map((note) => {
        let advisor = global.data.advisors.getAdvisor(note.advisorId)
        if (!advisor) {
          advisor = global.data.advisors.randomAdvisor()
        }

        const hydrated = {
          ...note,
          text: htmlAdapter(note.text),
          isAgenda: Str.toBool(note.isAgenda),
          isSummary: Str.toBool(note.isSummary),
          isPrivate: Str.toBool(note.isPrivate),
          createdAt: moment(note.createdAt),
          unreadMessages: 0,
          totalMessages: 0,
          task: global.tasks.parseTaskData(note.task),
          workflow: global.workflows.parseWorkflowData(note.workflow),
          // hasFieldsToFill: smartfieldStore.hasSmartfield(note.text),
          tags: note.tags || [],
          advisor,
          order: !Number.isInteger(note.order) ? 0 : note.order,
          included: noteIdsIncluded.includes(note.id),
        }

        this.hydrateNoteFileData(hydrated)
        return hydrated
      }),
    )
  }

  /**
   * @param {*} note
   * @returns
   */
  hydrateNoteFileData = (note) => {
    if (!global.data.docs.documents.length || !note.text) {
      note.files = []
      return
    }

    const files = [note.file1, note.file2, note.file3].filter(Boolean).map(global.data.docs.get)
    const { uploaded, audit } = isAuditNote({ ...note, files })

    if (audit && uploaded) {
      const uploadedAuditFileName = String(note.text.split(' - ')[0]).trim()
      if (uploadedAuditFileName !== files[0]?.name) {
        files.push({ id: uniqueId('uploaded-file'), uploaded: true, name: uploadedAuditFileName })
      }
    }

    const isMissingFile = files.length > 0 && !files.every(Boolean)
    if (isMissingFile) {
      files.push({ id: uniqueId('missing-file'), missing: true })
    }

    note.files = files.filter(Boolean)
    note.isMissingFile = isMissingFile
    note.isAudit = audit
  }

  getScratch = async (scratchId) => {
    // FIXME: should be a different method. There are no way to force a refresh of the scratch
    const localScratch = global.data.appt.scratchNotes.find((scratch) => scratch.id === Number(scratchId))
    if (localScratch) {
      return localScratch
    }

    global.app.loading = 'Fetching Handwritten Note'

    try {
      const scratch = await scratchService.getScratch(scratchId)
      global.data.appt.scratchNotes.push(scratch)

      return scratch
    } catch (error) {
      msg.error(['handwritten note', 'fetching'])
      captureExceptionSilently(error, { message: 'getScratch', data: { scratchId } })
    } finally {
      global.app.loading = false
    }
  }
}

export default Notes
