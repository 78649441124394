import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-PTG3S9F',
}

export default function googleTagManager() {
  if (process.env.REACT_APP_ENV === 'prod') {
    TagManager.initialize(tagManagerArgs)
  }
}
