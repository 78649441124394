import { SmartFieldConfig, SmartFieldIntegrationConfig } from 'components/drawers/Smartfields/types/data'
import {
  RiskalyzeFormValues,
  RiskalyzeQuestionaireFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import { RiskalyzeConfig, RiskalyzeQuestionaireConfig } from 'components/drawers/Smartfields/types/integration'

const PROPS = [
  'questionaire_type',
  'questionaire_link',
  'questionaire_link_title',
  'questionaire_contact',
  'questionaire_invested',
]

export const riskalyzeQuestionaireParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldConfig => {
  const questionaireValues = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<RiskalyzeFormValues<RiskalyzeQuestionaireFormValues>>
  >
  const newConfig = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<RiskalyzeConfig<RiskalyzeQuestionaireConfig>>
  >

  newConfig.provider = 'riskalyze'
  PROPS.map((prop) => {
    ;(newConfig as any)[prop] = (questionaireValues as any)[prop]
  })

  return newConfig as SmartFieldConfig
}

export const riskalyzeQuestionaireConfigParser = (
  values: SmartFieldIntegrationFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldIntegrationFormValues<RiskalyzeFormValues<RiskalyzeQuestionaireFormValues>> => {
  const intgrValues = values as SmartFieldIntegrationFormValues<RiskalyzeFormValues<RiskalyzeQuestionaireFormValues>>
  const intgrConfig = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<RiskalyzeConfig<RiskalyzeQuestionaireConfig>>
  >

  PROPS.map((prop) => {
    ;(intgrValues as any)[prop] = (intgrConfig as any)[prop]
  })

  return intgrValues
}
