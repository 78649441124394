import Icon, { ApiOutlined, CheckCircleFilled, DeleteOutlined, DisconnectOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Form, List, Modal, Row, Space, Tabs, Typography } from 'antd'
import { isConnected, isDisconnected, requiresAuth } from 'constants/connections'
import { FULL_DATE } from 'helpers/formats'
import { observer } from 'mobx-react'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import { useEffect, useState } from 'react'
import { MdOpenInNew } from 'react-icons/md'
import styled from 'styled-components'
import styles, { FlexCol, FlexRow } from 'styles'

const StyledCard = styled.div`
  .learn-more {
    > span {
      margin-bottom: 0px;
    }

    margin-top: 10px;
    &.reauthenticate {
      margin-top: 16px;
    }

    width: fit-content;
    color: white;
    .icon {
      margin-left: 6px;
    }
  }
  .description {
    flex: 1;
    .markdown {
      border-right: 1px solid ${styles.colors.layoutBorder};
      padding-right: 24px;
    }
  }
  .overview {
    margin-left: 24px;
    flex: 0 0 200px;
    height: 100%;
  }

  .medium {
    font-size: 16px;
  }

  .ant-list-item-meta-title {
    font-family: Lato !important;
    font-size: 16px !important;
  }
  .ant-list-item-action > li > div {
    font-family: Lato !important;
    font-size: 16px !important;
    color: black !important;
    cursor: default;
  }
`

const LearnMore = (props) => (
  <Button onClick={() => window.open(props.learnMoreLink, '_blank')} icon={<Icon component={MdOpenInNew} />}>
    Learn More
  </Button>
)

const Connect = (props) => {
  const label = props.connecting ? 'Connecting' : 'Connect'
  return (
    <Button
      loading={props.connecting}
      disabled={props.disabled}
      onClick={props.onClick}
      type="primary"
      icon={<ApiOutlined />}
    >
      {label}
    </Button>
  )
}

const Reconnect = (props) => (
  <Button
    loading={props.reconnecting}
    disabled={props.disabled}
    onClick={props.onClick}
    type="primary"
    danger
    icon={<ApiOutlined />}
  >
    Reconnect {props.name.toUpperCase()}
  </Button>
)

const Remove = (props) => {
  const label = props.disconnecting ? 'Removing Connection' : 'Remove Connection'
  return (
    <Button
      loading={props.disconnecting}
      disabled={props.disabled}
      onClick={props.onClick}
      danger
      icon={<DeleteOutlined />}
    >
      {label}
    </Button>
  )
}

const GetStarted = (props) => (
  <Button disabled={props.disabled} onClick={props.onClick} type="primary" icon={<ApiOutlined />}>
    Get Started
  </Button>
)

const Disconnect = (props) => {
  const label = props.connecting ? 'Disconnecting' : 'Disconnect'
  return (
    <Button
      loading={props.disconnecting}
      className="disconnect"
      onClick={props.onClick}
      danger
      icon={<DisconnectOutlined />}
    >
      {label}
    </Button>
  )
}

const onFinish = (props, isConnecting, form) => () => {
  form.validateFields().then((values) => {
    const { identifier } = props
    const credentials = {
      clientId: props.key1 || values.key1,
      clientSecret: props.key2 || values.key2,
    }
    const connection = global.connections.getConnection(identifier)
    connection.connect(credentials, identifier)

    isConnecting(true)
  })
}

const onReconnect = (props, isReconnecting) => (e) => {
  const { identifier: id } = props
  const integration = global.connections.getConnection(id)
  integration.reconnect(id)

  isReconnecting(true)
}

const onDisconnect = (props, isDisconnecting) => () => {
  Modal.confirm({
    title: props.name,
    content: 'Are you sure you want to disconnect this connection?',
    cancelText: 'Cancel',
    okType: 'danger',
    okText: 'Yes, Remove!',
    onOk: () => {
      const { identifier: id } = props
      const integration = global.connections.getConnection(id)
      integration.disconnect(id)

      isDisconnecting(true)
    },
  })
}

const Date = styled(FlexRow)`
  margin-top: 8px;
  font-size: 16px;
`

const Uninstall = styled(FlexCol)`
  border: 1px solid ${styles.colors.error};
  padding: 16px 24px 24px;

  p {
    margin-bottom: 0px !important;
  }
  .disconnect {
    margin-top: 24px !important;
  }
`

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`

const getAuthCode = async (code, identifier) => {
  const connection = global.connections.getConnection(identifier)
  if (connection) {
    await connection.connectCallback(code, identifier)
  }

  const path = identifier.split('-')[0]
  global.router.goto(`/settings/integrations/${path}`)
}

const BaseConnect = (props) => {
  const size = global.view.isLaptopDown ? 'default' : 'large'
  const [disconnecting, isDisconnecting] = useState(false)
  const [reconnecting, isReconnecting] = useState(false)
  const [connecting, isConnecting] = useState(false)
  const [tab, setTab] = useState('overview')
  const [form] = Form.useForm()

  useEffect(() => {
    const { code, state } = global.router.params
    if (code) {
      getAuthCode(code, state)
    }
  }, [])

  return (
    <StyledCard>
      <Tabs
        tabBarExtraContent={<Date>Added: {moment(props.createdAt).format(FULL_DATE)}</Date>}
        animated={{ inkBar: true, tabPane: false }}
        onChange={(t) => setTab(t)}
        activeKey={tab}
        size={size}
      >
        <Tabs.TabPane tab="Overview" key="overview">
          <FlexRow align="flex-start">
            <FlexCol className="description">
              {requiresAuth(props.state) && (
                <StyledAlert
                  message={`There's a problem with your credentials`}
                  type="error"
                  description={`We need to send you to ${props.name}, to update your login details.`}
                  showIcon
                />
              )}
              <div className="markdown medium">
                {requiresAuth(props.state) ? (
                  "You'll be redirected back to Pulse360 when the connection has been re-established."
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: props.desc }} />
                )}
              </div>
              <FlexRow style={{ marginTop: 24 }}>
                <Space>
                  {isDisconnected(props.state) && <GetStarted onClick={() => setTab('setup')} />}
                  {requiresAuth(props.state) && (
                    <Reconnect
                      reconnecting={reconnecting}
                      onClick={onReconnect(props, isReconnecting)}
                      name={props.name}
                    />
                  )}
                  {props.learnMoreLink && isDisconnected(props.state) && (
                    <LearnMore learnMoreLink={props.learnMoreLink} />
                  )}
                  {!isDisconnected(props.state) && (
                    <Remove
                      disconnecting={disconnecting}
                      color={requiresAuth(props.state) ? 'black' : 'red'}
                      onClick={onDisconnect(props, isDisconnecting)}
                      name={props.name}
                    />
                  )}
                </Space>
              </FlexRow>
            </FlexCol>
            <div className="overview">
              <List pagination={false}>
                {props.features.map((feat) => (
                  <List.Item
                    key={feat}
                    actions={[<CheckCircleFilled style={{ color: styles.colors.green }} key={feat} />]}
                  >
                    <List.Item.Meta title={feat} />
                  </List.Item>
                ))}
              </List>
            </div>
          </FlexRow>
        </Tabs.TabPane>

        {!requiresAuth(props.state) && (
          <Tabs.TabPane tab={isConnected(props.state) ? 'Disconnect' : 'Connect'} key="setup">
            {isConnected(props.state) && (
              <Uninstall style={{ marginTop: 10 }}>
                <Typography.Title level={3} style={{ color: styles.colors.error }}>
                  Danger Zone
                </Typography.Title>
                <p>Disconnect {props.name}?</p>
                <p>This will remove the connection and revoke access to all resources.</p>
                <Row>
                  <Col>
                    <Disconnect disconnecting={disconnecting} onClick={onDisconnect(props, isDisconnecting)} />
                  </Col>
                </Row>
              </Uninstall>
            )}

            {isDisconnected(props.state) && (
              <FlexCol>
                {props.renderSettings(form, onFinish(props, isConnecting, form))}
                <Row>
                  <Col>
                    <Connect connecting={connecting} onClick={onFinish(props, isConnecting, form)} />
                  </Col>
                </Row>
              </FlexCol>
            )}
          </Tabs.TabPane>
        )}
      </Tabs>
    </StyledCard>
  )
}

BaseConnect.LearnMore = LearnMore
BaseConnect.LearnMore.propTypes = {
  learnMoreLink: PropTypes.string.isRequired,
}

BaseConnect.propTypes = {
  learnMoreLink: PropTypes.string.isRequired,
  features: PropTypes.array,
  key1: PropTypes.string,
  key2: PropTypes.string,
  key3: PropTypes.string,
}

BaseConnect.defaultProps = {
  features: [],
}

export default observer(BaseConnect)
