import { SmartFieldConfig, SmartFieldIntegrationConfig } from 'components/drawers/Smartfields/types/data'
import {
  RiskalyzeDataFormValues,
  RiskalyzeFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import { RiskalyzeConfig, RiskalyzeDataConfig } from 'components/drawers/Smartfields/types/integration'

export const riskalyzeModelParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldConfig => {
  const questionaireValues = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<RiskalyzeFormValues<RiskalyzeDataFormValues>>
  >
  const newConfig = config as SmartFieldConfig<SmartFieldIntegrationConfig<RiskalyzeConfig<RiskalyzeDataConfig>>>

  newConfig.provider = 'riskalyze'
  newConfig.data_fields = JSON.stringify(questionaireValues.data_fields)

  return newConfig as SmartFieldConfig
}

export const riskalyzeModelConfigParser = (
  values: SmartFieldIntegrationFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldIntegrationFormValues<RiskalyzeFormValues<RiskalyzeDataFormValues>> => {
  const intgrValues = values as SmartFieldIntegrationFormValues<RiskalyzeFormValues<RiskalyzeDataFormValues>>
  const intgrConfig = config as SmartFieldConfig<SmartFieldIntegrationConfig<RiskalyzeConfig<RiskalyzeDataConfig>>>

  intgrValues.data_fields = JSON.parse(intgrConfig.data_fields)

  return intgrValues
}
