import { captureExceptionSilently } from 'helpers/sentry'
import { compact, isEmpty, isNil, omitBy, union, upperFirst } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import moment from 'moment'
import { msg } from 'stores/msg'
import { EntityId } from 'types/entity.interface'
import { Client, ClientInput } from 'types/graphql'
import { ExistsInCrm, NotExistsInCRMWarningText } from '../../constants/client'
import clientsService from '../services/clients.service'

export interface ClientAlias {
  crmOrFirst: boolean
  value: string
  isDefault: boolean
  rawValue: string
}

class Clients {
  // store info panel data
  client: Client | null = null
  columns = []

  get clientId() {
    if (this.client) {
      return this.client.id
    }
    return undefined
  }

  get aliases() {
    if (this.client && this.client?.aliases) {
      return this.getAliases(this.client.aliases)
    }
    return []
  }

  constructor() {
    makeObservable(this, {
      client: observable,
      clientId: computed,
      aliases: computed,
      loadClient: action.bound,
      unloadClient: action.bound,
      addClient: action.bound,
      updateClient: action.bound,
      setAliases: action.bound,
    })

    // FIXME: needs to load dayna ID from server
    /* observe(
      this,
      'data',
      (c) => {
        if (Array.isArray(c.newValue) && c.newValue.length) {
          const defaultHelpHeroClient = c.newValue.find(
            (client) =>
              client.email === 'testing@pulse360.com' && client.lastName === 'Abshire' && client.firstName === 'Dayna',
          )
          defaultHelpHeroClient && updateHelpHero({ defaultUserId: defaultHelpHeroClient.id })
        }
      },
      true,
    ) */
  }

  async getClient(clientId: EntityId, includeArchived?: boolean) {
    const client = clientsService.getClient(clientId, includeArchived)

    return client
  }

  /**
   * Deletes a client (soft delete)
   * @param {*} clientId
   */
  archiveClient = async (clientId: EntityId) => {
    await clientsService.deleteClient(clientId)
  }

  /**
   * Restores a soft deleted client
   * @param {*} clientId
   */
  unarchiveClient = async (clientId: EntityId) => {
    await clientsService.restoreClient(clientId)
  }

  /**
   * Load specific client information
   */
  async loadClient(clientId: EntityId) {
    const client = await this.getClient(clientId)

    this.client = client
    return this.client
  }

  /**
   * Load specific client information
   */
  unloadClient() {
    this.client = null
    return Promise.resolve(this.client)
  }

  /**
   *
   */
  async addClient(data: ClientInput) {
    const fields = omitBy(data, isNil)
    const client = await clientsService.addClient(fields)
    if (client) {
      return client
    }
  }

  /**
   *
   */
  async updateClient(
    clientId: EntityId,
    { strategies, checklist, ...fields }: { strategies?: any; checklist?: any } & ClientInput,
  ) {
    runInAction(() => {
      if (this.clientId === clientId && this.client) {
        ;(Object.keys(fields) as (keyof typeof fields)[]).forEach((key) => {
          this.client![key] = fields[key]
        })
      }
    })
    const updated = await clientsService.updateClient(clientId, fields)
    return updated
  }

  /**
   * Safe failover for client names
   */
  getClientName = (client = this.client): string => {
    if (!client) {
      return 'None Selected'
    }
    const { firstName, lastName, salutation, greeting, suffix } = client

    if (lastName) {
      let name = lastName

      if (firstName) {
        name = `${name}, ${firstName}`

        if (suffix) {
          name = `${name} ${suffix}`
        }
      }

      return name
    }

    if (greeting) {
      return greeting
    }
    if (salutation) {
      return salutation
    }

    return firstName as string
  }

  getClientNameWithAlias = (client = this.client) => {
    if (!client) {
      return 'None Selected'
    }
    const { firstName, lastName, salutation, greeting, suffix, middle } = client
    const alias = this.getAlias(client)
    const formatedAlias = alias ? ` "${alias}"` : ''

    if (lastName) {
      let name = lastName

      if (firstName) {
        name = `${name}, ${firstName}`
        name = `${name} ${formatedAlias}`
        if (middle) {
          name = `${name}, ${middle}`
        }

        if (suffix) {
          name = `${name}, ${suffix}`
        }
      }

      return name
    }

    if (greeting) {
      return greeting
    }
    if (salutation) {
      return salutation
    }

    return firstName || 'NO NAME'
  }

  /**
   * Safe failover for client names
   */
  getFullName = (client = this.client, isFormal = false, useAlias = true) => {
    if (!client) {
      return 'None Selected'
    }
    const { firstName, lastName, suffix, prefix, middle } = client

    if (isEmpty(firstName) && !isEmpty(lastName)) {
      return lastName
    }
    if (!isEmpty(firstName) && isEmpty(lastName)) {
      return firstName
    }
    const suf = suffix ? `, ${suffix}` : ''
    const mid = middle ? ` ${middle}` : ''

    if (isFormal) {
      const pre = prefix ? `${prefix} ` : ''
      return `${pre}${firstName}${mid} ${lastName}${suf}`
    }

    if (useAlias) {
      const alias = this.getAlias(client)
      const formatedAlias = alias ? ` "${alias}"` : ''

      return `${firstName}${formatedAlias}${mid} ${lastName}${suf}`
    }
    return `${firstName}${mid} ${lastName}${suf}`
  }

  getOfficialName = (client = this.client) => {
    if (!client) {
      return ''
    }
    const { lastName } = client
    if (!lastName || isEmpty(lastName)) {
      return null
    }
    return `${this.getGenderTitle(client)} ${upperFirst(lastName)}`
  }

  getGenderTitle = (client: Client) => {
    const { gender } = client!
    if (gender === 'M') {
      return `Mr`
    }
    if (gender === 'F') {
      return `Mrs`
    }
    return `Mx`
  }

  /**
   * Returns the greeting to use for multiple clients
   *  ie. Tom, Dick and Harry
   * @param {*} client
   */
  getFullOrLinkedGreeting = (client: Client) => {
    // create an array of clients, with this being the first
    const clients = [client]
    if (clients.length === 1) {
      return {
        names: this.getFullName(client, true),
        count: clients.length,
      }
    }

    // FIXME: will always come out for the early return
    // concat all linked clients and join all with a comma
    const names = clients
      .map((c) => {
        if (c.greeting) {
          return c.greeting
        } else {
          return this.getFullName(c, true)
        }
      })
      .join(', ')
    // replace the last comma with 'and' so that we have a valid structure:
    // Tom, Dick and Harry :)

    return {
      names: names.replace(/, (?=[^, ]*$)/, ' and ').trim(),
      count: clients.length,
    }
  }

  /**
   *
   */
  getFormalGreeting = (client: Client = this.client as Client) => {
    // FIXME: the parameters should not be modified, using an element of the class as a default parameter does not seem to me to be a good practice.
    if (!client) {
      return ''
    }

    if (!isEmpty(client.salutation)) {
      return client.salutation || ''
    }
    return this.getFullOrLinkedGreeting(client).names || ''
  }

  /**
   * Generates a list of salutations by client id (uses current client if not specified).
   * Keep in mind it's different from accessing `client.salutation`
   * @param client
   * @returns string[]
   */
  getSalutations = (client = this.client!) => {
    const salutations = compact([this.getFullName(client), this.getOfficialName(client)])

    const clients = [client]
    if (clients.length === 1) {
      return salutations
    }
    // FIXME: will always come out for the early return
    // @ts-ignore
    const names = clients.map((c) => upperFirst(c.firstName)).join(', ')
    salutations.push(names.replace(/, (?=[^, ]*$)/, ' and '))
    if (clients.length === 2) {
      const genders = clients.map((c) => this.getGenderTitle(c)).join(` and `)
      salutations.push(`${genders} ${client.lastName}`)
    }
    return salutations
  }

  getAliases = (aliases = ''): ClientAlias[] => {
    const [crmAlias, ...pulseAliases] = (aliases || '').split('||')

    const aliasesArray = [crmAlias].concat(compact(pulseAliases))

    return aliasesArray.map((value, index) => ({
      crmOrFirst: !index,
      value: value.replaceAll('__default', ''),
      isDefault: value.includes('__default'),
      rawValue: value,
    }))
  }

  setAliases = (clientId: EntityId, aliases: string) => {
    this.updateClient(clientId, { aliases })
  }

  getAlias = (client = this.client!) => {
    const { aliases } = client
    if (!aliases) {
      return null
    }
    const alias = this.getAliases(aliases)
    if (alias.length === 0) {
      return null
    }
    const defaultAlias = alias.find((aliasData) => aliasData.isDefault)
    if (defaultAlias) {
      return defaultAlias.value
    }
    return alias[0].value
  }

  getGreetings = (client = this.client!) => {
    const stock = this.getSalutations(client)
    if (!client.aliases) {
      return stock
    }

    const aliases = this.getAliases(client.aliases)

    return union(
      aliases
        .slice()
        .sort((a, b) => +b.isDefault - +a.isDefault)
        .map(({ value }) => value)
        .filter((value) => String(value).trim()),
      stock,
    )
  }

  /**
   * Returns a Client's address
   */
  getAddressParts = (client = this.client!) => {
    const lines = ['address1', 'address2', 'city', 'state', 'zip'] as (keyof Client)[]
    return lines.reduce<string[]>((addr, line) => {
      const part = client[line]
      if (!isEmpty(part)) {
        addr.push(part)
      }
      return addr
    }, [])
  }

  checkExistenceInCrm = async (client: Client) => {
    try {
      // @ts-ignore
      const hasIntegration = global.ext.enabledCRM

      if (!hasIntegration) {
        return
      }

      if (client.existsInCrm === ExistsInCrm.NOTAPPLICABLE) {
        return
      }

      const exists = await clientsService.clientExistsInCrm(+client.id)

      if (exists === client.existsInCrm) {
        return
      }

      client.existsInCrm = exists

      if (client.existsInCrm === ExistsInCrm.NONEXISTENT) {
        msg.error(NotExistsInCRMWarningText, '', 10)
      }
    } catch (error) {
      captureExceptionSilently(error, {
        message: 'checkExistenceInCrm',
        data: { clientId: client.id },
      })
    }
  }

  getPhoneAndType = (client = this.client!) => {
    if (client.businessPhone) {
      return {
        phone: client.businessPhone,
        type: 'Business',
      }
    }

    if (client.homePhone) {
      return {
        phone: client.homePhone,
        type: 'Home',
      }
    }

    return
  }

  getPhone = (client = this.client!) => {
    return this.getPhoneAndType(client)?.phone
  }

  getAge = (client = this.client!) => {
    const { dob } = client
    if (!dob) {
      return null
    }
    const age = moment().diff(dob, 'years', false)
    return age
  }
}

export default Clients
