import { Form, Input } from 'antd'
import { FlexCol } from 'styles'
import Connect from '../connect'

const SalesforceConnect = (props) => (
  <Connect
    learnMoreLink="https://www.salesforce.com/"
    {...props}
    features={['Sync Contacts', 'Sync Events']}
    renderSettings={(form, submit) => (
      <FlexCol>
        <Form onFinish={submit} form={form}>
          <Form.Item label="Instance URL" name="baseUrl" rules={[{ required: true, message: 'Enter instance url!' }]}>
            <Input size="large" autoFocus />
          </Form.Item>
          <Form.Item label="Consumer Key" name="key1" rules={[{ required: true, message: 'Enter consumer key!' }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Consumer Secret"
            name="key2"
            rules={[{ required: true, message: 'Enter consumer secret!' }]}
          >
            <Input size="large" />
          </Form.Item>
        </Form>
      </FlexCol>
    )}
  />
)

export default SalesforceConnect
