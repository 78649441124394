import { FormInstance } from 'antd'
import { SmartFieldContainer } from 'components/drawers/Smartfields/smartfield.container'
import { SmartField } from 'components/drawers/Smartfields/types'
import { usePreciseFP } from './precisefp.hooks'

interface SmartFieldIntegrationPreciseFPProps {
  smartfield?: SmartField
  form: FormInstance
}

export const SmartFieldIntegrationPreciseFP: React.FC<SmartFieldIntegrationPreciseFPProps> = ({ form }) => {
  const { connected } = usePreciseFP()

  return (
    <SmartFieldContainer
      title="PreciseFP Options"
      connectionError={!connected}
      provider="precisefp"
      testId="smartfield-precisefp-form"
    >
      <></>
    </SmartFieldContainer>
  )
}
