import { htmlAdapter } from 'lib/remirror/util/froala.adapter'
import { filter, find, isNil, isObject, kebabCase, omit } from 'lodash'
import { action, computed, makeObservable, observable, observe, runInAction, toJS } from 'mobx'
import m from 'moment'
import { msg } from 'stores/msg'
import { captureExceptionSilently } from '../../helpers/sentry'
import collectionService from '../services/collection.service'
import summaryService from '../services/summary.service'
import topicService from '../services/topic.service'

class Topics {
  _topics = []
  selectedTopics = []
  selectedFilter = null
  _summaries = []
  summaries = []
  _collections = {}
  collectionOptions = []
  lastTopicCreated = null

  constructor(props) {
    makeObservable(this, {
      lastTopicCreated: observable,
      _topics: observable,
      topics: computed,
      deletedTopics: computed,
      selectedTopics: observable,
      selectedFilter: observable,
      _summaries: observable,
      summaries: observable,
      collections: computed,
      _collections: observable,
      collectionOptions: observable,
      loadTopics: action.bound,
      loadAllTopics: action.bound,
      loadCollections: action.bound,
      addSummary: action.bound,
      updateSummary: action.bound,
      deleteSummaries: action.bound,
      deleteTopic: action.bound,
      restoreTopic: action.bound,
      getTopicRelations: action.bound,
      loadTopicsFilters: action.bound,
      filterSummaries: action.bound,
      updateTopic: action.bound,
      updateCollectionItems: action.bound,
      createCollection: action.bound,
      updateCollection: action.bound,
      deleteCollection: action.bound,
    })

    observe(
      global.auth,
      'signedIn',
      (c) => {
        if (c.newValue) {
          this.loadTopics()
          this.loadCollections()
          this.loadAllTopics()
        }
      },
      true,
    )
  }

  get deletedTopics() {
    return this._topics.filter((topic) => topic.deletedAt).sort((a, b) => a.name.localeCompare(b.name))
  }

  get topics() {
    return this._topics.filter((topic) => !topic.deletedAt).sort((a, b) => a.name.localeCompare(b.name))
  }

  get collections() {
    const collectionWithSummaries = Object.values(
      this._summaries.reduce((acum, item) => {
        item.collections.forEach((itemCollection, index) => {
          const fullCollectionData = this._collections[itemCollection.relation.collectionId]
          const collectionId = fullCollectionData?.id
          if (!collectionId) {
            return acum
          }
          const relation = {
            ...itemCollection.relation,
            itemId: item.id,
            collectionId,
            index,
          }
          if (collectionId in acum) {
            acum[collectionId] = {
              id: collectionId,
              collection: fullCollectionData,
              lastUpdatedAt: acum[collectionId].lastUpdatedAt.isAfter(relation.createdAt)
                ? acum[collectionId].lastUpdatedAt
                : m(relation.createdAt),
              items: acum[collectionId].items.concat({
                ...item,
                _relation: relation,
              }),
              relations: acum[collectionId].relations.concat(relation),
            }
          } else {
            acum[collectionId] = {
              id: collectionId,
              collection: fullCollectionData,
              lastUpdatedAt: m(fullCollectionData.updatedAt).isAfter(relation.createdAt)
                ? m(fullCollectionData.updatedAt)
                : m(relation.createdAt),
              items: [{ ...item, _relation: relation }],
              relations: [relation],
            }
          }
        })
        return acum
      }, {}),
    ).map((collection) => {
      collection.items = collection.items.sort((itemA, itemB) => {
        const setOrder = itemA._relation.order - itemB._relation.order
        if (!setOrder) {
          return m(itemA._relation.createdAt).diff(itemB._relation.createdAt)
        }
        return setOrder
      })
      return collection
    })

    const collectionWithNoSummaries = this.collectionOptions
      .filter(({ id }) => !collectionWithSummaries.some((col) => col.id === id))
      .map((collection) => ({
        id: collection.id,
        collection,
        lastUpdatedAt: m(collection.updatedAt),
        items: [],
        relations: [],
      }))

    return collectionWithSummaries
      .concat(collectionWithNoSummaries)
      .sort((collectionA, collectionB) => {
        const setOrder = Number(collectionB.collection.favorite) - Number(collectionA.collection.favorite)
        if (!setOrder) {
          return String(collectionA.collection.name).localeCompare(collectionB.collection.name)
        }
        return setOrder
      })
      .map((item, index) => {
        item._index = index
        return item
      })
  }

  /**
   * @param {*} id
   */
  async loadTopics() {
    try {
      const topics = await topicService.getTopics()
      this._topics = topics

      return topics
    } catch (error) {
      msg.error(['task category', 'fetching'])
      captureExceptionSilently(error, { message: 'loadTopics' })
    }
  }

  /**
   *  WARNING: This method loads summaries not topics
   *  to load topics use loadTopics
   *
   */
  async loadAllTopics() {
    await this.loadSummaries()
    this.selectedTopics = []
    this.selectedFilter = null
  }

  async loadSummaries() {
    try {
      const summaries = await summaryService.getSummaries()
      const migratedSummaries = summaries.map((summary) => ({ ...summary, text: htmlAdapter(summary.text) }))

      this._summaries = migratedSummaries
      this.summaries = migratedSummaries

      return migratedSummaries
    } catch (error) {
      msg.error(['library', 'fetching'])
      captureExceptionSilently(error, { message: 'loadSummaries' })
    }
  }

  /**
   * @param {*} id
   */
  async loadCollections() {
    try {
      this._collections = {}
      const collections = await collectionService.getCollections()
      this._setCollections(collections)
      return collections
    } catch (err) {
      captureExceptionSilently(err, { message: 'loadCollections' })
      msg.error(err.message)
    }
  }

  /**
   * @param {*} summary
   */
  async addSummary(payload) {
    try {
      const { topicId, summary: summaryData } = payload
      const summary = await summaryService.createSummary({
        topicId,
        summary: omit(summaryData, 'taskTemplate.linkedTo', 'workflowTemplate.linkedTo'),
      })
      msg.success('Added Note Template')
      this._summaries.push(summary)
      this.filterSummaries()

      return summary
    } catch (error) {
      msg.error(['summary', 'adding'])
      captureExceptionSilently(error, {
        message: 'addSummary',
        data: { payload },
      })
    }
  }

  /**
   * @param {*} summary
   */
  async updateSummary(id, payload) {
    try {
      const localSummaryIndex = this._summaries.findIndex((s) => s.id === id)
      if (localSummaryIndex === -1) {
        throw new Error('No Micro-template found with that ID', id)
      }

      const summary = await summaryService.updateSummary({
        id,
        summary: {
          ...omit(payload, 'taskTemplate.linkedTo', 'workflowTemplate.linkedTo'),
          content: isObject(payload.content) ? JSON.stringify(payload.content) : payload.content,
        },
      })
      msg.success('Micro-template Updated')
      this._summaries.splice(localSummaryIndex, 1, summary)
      this.filterSummaries()

      return summary
    } catch (error) {
      msg.error(['summary', 'updating'])
      captureExceptionSilently(error, {
        message: 'updateSummary',
        data: { id, payload },
      })
    }
  }

  /**
   * @param {*} summary
   */
  async deleteSummaries(summaryIds) {
    try {
      await summaryService.deleteSummaries({ ids: summaryIds })
      this._summaries = this._summaries.filter(({ id }) => !summaryIds.some((s) => s === id))
      msg.success(`Micro Template${summaryIds.length > 1 ? 's' : ''} deleted successfully`)
      this.filterSummaries()
    } catch (error) {
      msg.error(`There was an issue deleting that information. We'll take a look`)
      captureExceptionSilently(error, {
        message: 'deleteSummaries',
        data: { summaryIds },
      })
    }
  }

  loadTopicsFilters(topics = [], filter) {
    this.selectedFilter = filter
    switch (filter && filter.id) {
      case 'PDefault':
        this.filterSummaries(topics, false, true)
        break
      case 'UDefault':
        this.filterSummaries(topics, false, true)
        break
      case 'PFavourite':
        this.filterSummaries(topics, true, false)
        break
      case 'UFavourite':
        this.filterSummaries(topics, true, false)
        break
      default:
        this.filterSummaries(topics)
        break
    }
  }

  /**
   * @param {*} searchParam
   */
  filterSummaries(searchParam = this.selectedTopics, isFavourite, isDefault) {
    let summaries = filter(this._summaries, (s) => {
      if (!searchParam.length) {
        return true
      }
      return searchParam.includes(s.topicId)
    })
    if (!isNil(isFavourite)) {
      summaries = filter(summaries, (s) => Boolean(s.isFavourite) === isFavourite)
    }
    if (!isNil(isDefault)) {
      summaries = filter(summaries, (s) => Boolean(s.isDefault) === isDefault)
    }
    this.selectedTopics = searchParam
    this.summaries = summaries
  }

  /**
   * @param {*} id
   */
  getTopic = (id) => {
    return find(this._topics, { id: Number(id) })
  }

  /**
   * @param {*} id
   */
  getTopicName = (id) => {
    const topic = find(this._topics, { id: Number(id) })
    return topic ? topic.name : id
  }

  /**
   * @param {number} collectionId
   * @param {number[]} items
   * @param {boolean} justAdding
   * @param {number[]} itemsToUpdate
   */
  updateCollectionItems = async (collectionId, itemsToUpdate, justAdding) => {
    const collection = this.collections.find(({ id }) => collectionId === id)
    const numberOfItems = justAdding ? collection.items.length : 0
    const summaries = toJS(this._summaries)
    const summariesBk = toJS(this._summaries)
    itemsToUpdate.forEach(({ id, present, index }) => {
      const localSummaryIndex = summaries.findIndex((s) => s.id === id)
      if (localSummaryIndex !== -1) {
        const summary = summaries[localSummaryIndex]

        const clearedCollections = summary.collections.filter(({ relation }) => collectionId !== relation.collectionId)
        const relation = {
          collectionId,
          order: numberOfItems + index,
          createdAt: new Date(),
        }
        const collections = !present ? clearedCollections : clearedCollections.concat({ relation })

        summaries.splice(localSummaryIndex, 1, { ...summary, collections })
      }
    })
    this._summaries = summaries
    this.filterSummaries()
    const items = itemsToUpdate
      .filter(({ present }) => present)
      .sort((a, b) => a.index - b.index)
      .map(({ id }) => id)

    let resp
    try {
      if (justAdding) {
        resp = await collectionService.addCollectionItems(collectionId, items)
      } else {
        resp = await collectionService.setCollectionItems(collectionId, items)
      }
    } catch (err) {
      if (!resp) {
        this._summaries = summariesBk
      }
    }
  }

  _setCollections(data) {
    this.collectionOptions = data
    this._collections = data.reduce((acum, item) => {
      acum[item.id] = item
      return acum
    }, {})
  }

  getCollectionOption(id) {
    return this._collections[id]
  }

  createCollection = async (collection) => {
    try {
      const createdCollection = await collectionService.createCollection(collection)
      this._setCollections(this.collectionOptions.concat(createdCollection))
      return createdCollection
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'createCollection',
        data: { collection },
      })
      throw err
    }
  }

  updateCollection = async (id, collection) => {
    try {
      const updatedCollectionId = await collectionService.updateCollection(id, collection)
      this._setCollections(
        this.collectionOptions.filter((colOpt) => colOpt.id !== id).concat({ id, ...collection, updateAt: new Date() }),
      )
      return updatedCollectionId
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'updateCollection',
        data: { id, collection },
      })
      throw err
    }
  }

  deleteCollection = async (id, items = []) => {
    try {
      const deletedCollectionId = await collectionService.deleteCollection(id)

      const summaryList = toJS(this._summaries)

      runInAction(() => {
        items.forEach((summary) => {
          const index = summaryList.findIndex((s) => s.id === summary.id)
          if (index !== -1) {
            summaryList.splice(index, 1, {
              ...summaryList[index],
              collections: summaryList[index].collections.filter(({ relation }) => relation.collectionId !== id),
            })
          }
        })
        this._summaries = summaryList
        this._setCollections(this.collectionOptions.filter((colOpt) => colOpt.id !== id))
        this.filterSummaries()
      })

      return deletedCollectionId
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'deleteCollection',
        data: { id },
      })
      throw err
    }
  }

  addTopic = async (name, dueDays) => {
    try {
      const topic = await topicService.createTopic({ topic: { name, dueDays } })
      this.lastTopicCreated = topic
      this._topics = [topic, ...this._topics]
      msg.success(`Topic ${name} added`)

      return topic
    } catch (error) {
      msg.error(error.message)
      captureExceptionSilently(error, {
        message: 'addTopic',
        data: { topic: { name, dueDays } },
      })
    }
  }

  editTopic = async (id, name, dueDays) => {
    const topic = { id, name, dueDays }
    try {
      await topicService.updateTopic({ topic })
      this.updateTopic(id, topic)
      this.filterSummaries()
      msg.success('Topic Updated')

      return id
    } catch (error) {
      msg.error(error.message)
      captureExceptionSilently(error, { message: 'edit', data: topic })
    }
  }

  getTopicRelations = async (topic) => {
    try {
      const topicRelationCount = await topicService.getTopicRelationsCount({
        id: topic.id,
      })

      return topicRelationCount
    } catch (error) {
      msg.error(error.message)
      captureExceptionSilently(error, {
        message: 'getTopicRelationsCount',
        data: topic,
      })
      return {}
    }
  }

  deleteTopic = async (topic, replacementId) => {
    try {
      const id = await topicService.deleteTopic({
        topicId: topic.id,
        replacementId,
      })
      this.updateTopic(topic.id, { deletedAt: new Date().toISOString() })
      if (replacementId) {
        this.loadAllTopics().then(() => this.filterSummaries())
      }
      msg.success('Topic Archived')
      return id
    } catch (error) {
      msg.error(error.message)
      captureExceptionSilently(error, {
        message: 'deleteTopic',
        data: { topic, replacementId },
      })
    }
  }

  restoreTopic = async (topic) => {
    try {
      const id = await topicService.restoreTopic({ topicId: topic.id })
      if (id) {
        this.updateTopic(topic.id, { deletedAt: null })
      }

      return id
    } catch (error) {
      msg.error(error.message)
      captureExceptionSilently(error, {
        message: 'restoreTopic',
        data: { topic },
      })
    }
  }

  /**
   * @param {*} topic
   */
  updateTopic(id, topic) {
    const index = this._topics.findIndex((o) => o.id === id)
    this._topics = [
      ...this._topics.slice(0, index),
      { ...this._topics[index], ...topic },
      ...this._topics.slice(index + 1),
    ]
  }

  findTopic = (name) => {
    return find(this._topics, { name })
  }

  /**
   * @param {*} id
   */
  getSummary = (id) => {
    return find(this._summaries, { id: Number(id) })
  }

  prepareSummary = (summaryData) => {
    const {
      topicName,
      text,
      content,
      isSummary = false,
      isAgenda = false,
      isDefault = false,
      isFavourite = false,
      isPrivate = false,
      taskTemplate,
      workflowTemplate,
      file1 = 0,
      file2 = 0,
      file3 = 0,
      tags = [],
      collections = [],
    } = summaryData

    const topic = global.data.topics.findTopic(topicName)
    const payload = {
      topicId: topic.id,
      summary: {
        isFavourite,
        isDefault,
        isPrivate,
        isSummary,
        isAgenda,
        text,
        content: isObject(content) ? JSON.stringify(content) : content,
        file1,
        file2,
        file3,
        tags: tags.map(({ __typename, updatedAt, ...tag }) => tag),
        collections: collections.map((col) => ({
          collectionId: typeof col === 'number' ? col : col.id,
          order: typeof col === 'number' ? 999 : col.order,
        })),
        topicId: topic.id,
      },
    }

    if (taskTemplate) {
      payload.summary.taskTemplate = taskTemplate
    }
    if (workflowTemplate) {
      payload.summary.workflowTemplate = workflowTemplate
    }
    return { payload, topic }
  }

  getTopicByKebabCasedName = (kebabNameToFind) => {
    return this.topics.find((topic) => kebabCase(topic.name) === kebabNameToFind) || {}
  }
}

export default Topics
