import { useEffect, useState } from 'react'

interface Provider {
  value: string
  label: string
  icon: any
  disabled: boolean
}

export const useProviders = (
  supportedProviders: string[] = [],
  visibleProviders: string[] = [],
) => {
  const [providers, setProviders] = useState<Provider[]>([])

  useEffect(() => {
    const connections = global.connections.installed
    setProviders(
      connections
        .filter((conn) =>
          visibleProviders.length
            ? visibleProviders.includes(conn.local)
            : true,
        )
        .map((conn) => ({
          value: conn.identifier,
          label: !supportedProviders.includes(conn.local)
            ? `${conn.name} (coming soon)`
            : conn.name,
          icon: conn.Icon,
          disabled: !supportedProviders.includes(conn.local),
        }))
        .sort((a) => (a.disabled ? 1 : -1)),
    )
  }, [])

  return {
    providers,
  }
}
