import { Form, Input } from 'antd'
import Connect from '../connect'

const RiskalyzeConnect = (props) => (
  <Connect
    learnMoreLink="https://www.riskalyze.com/"
    {...props}
    features={[]}
    renderSettings={(form, submit) => (
      <Form onFinish={submit} form={form}>
        <Form.Item label="Client ID" name="key1" rules={[{ required: true, message: 'Enter Client ID!' }]}>
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Client Secret" name="key2" rules={[{ required: true, message: 'Enter Client Secret!' }]}>
          <Input size="large" />
        </Form.Item>
      </Form>
    )}
  />
)

RiskalyzeConnect.defaultProps = {
  baseUrl: 'https://pro.riskalyze.com',
}

export default RiskalyzeConnect
