/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, FormInstance, Select } from 'antd'
import { SmartFieldContainer } from 'components/drawers/Smartfields/smartfield.container'
import { SmartField } from 'components/drawers/Smartfields/types'
import { SmartFieldConfig, SmartFieldIntegrationConfig } from 'components/drawers/Smartfields/types/data'
import { HolistiplanConfig } from 'components/drawers/Smartfields/types/integration'
import { useCallback } from 'react'
import { FormItemLabel } from '../../../smartfield.styles'
import { SmartFieldIntegrationFormatter } from '../../components/format.selector'
import { taxRecordFields } from './holistiplan.constants'
import { useHolistiplanConnection } from './holistiplan.hooks'

interface SmartFieldIntegrationHolistiplanProps {
  form: FormInstance
  smartfield?: SmartField
}

export const SmartFieldIntegrationHolistiplan: React.FC<SmartFieldIntegrationHolistiplanProps> = ({
  form,
  smartfield,
}) => {
  const config = smartfield?.config as SmartFieldConfig<SmartFieldIntegrationConfig<HolistiplanConfig>>
  const { connected } = useHolistiplanConnection()

  const onSelectTaxField = useCallback(
    (taxField: string, options: any) => {
      form.setFieldValue('label', options.children)
    },
    [form],
  )

  return (
    <SmartFieldContainer
      title="Holistiplan Options"
      connectionError={!connected}
      provider="holistiplan"
      testId="smartfield-holistiplan-form"
    >
      <Form.Item
        name="field"
        rules={[{ required: true, message: 'Please select a tax field' }]}
        label={<FormItemLabel label="Tax record field" description="Select tax record field to use" />}
        initialValue={config?.field}
        preserve={false}
      >
        <Select
          onChange={onSelectTaxField}
          placeholder="Select tax field"
          filterOption={(input, option) => {
            return String(option?.value ?? '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }}
          style={{ width: '100%' }}
          size="large"
          showSearch
        >
          {Object.keys(taxRecordFields)
            .sort((a, b) => a.localeCompare(b))
            .map((field) => (
              <Select.Option value={field}>{(taxRecordFields as any)[field]}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      <SmartFieldIntegrationFormatter form={form} smartfield={smartfield} />
    </SmartFieldContainer>
  )
}
