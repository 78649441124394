import { CATEGORIES, STATES, isEnabled } from 'constants/integrations'
import { updateHelpHero } from 'helpers/sentry'
import { filter, find, get } from 'lodash'
import { action, computed, makeObservable, observable, observe } from 'mobx'

class Integrations {
  integrationSync = []
  integrations = []
  categories = CATEGORIES
  states = STATES

  get installed() {
    return filter(this.integrations, (i) => {
      return i.state !== STATES.HIDDEN
    })
  }

  get disabled() {
    return filter(this.integrations, { state: STATES.DISABLED })
  }

  get enabled() {
    return filter(this.integrations, (i) => isEnabled(i.state))
  }

  get enabledCRM() {
    return get(this.enabled, '[0].name', '')
  }

  get hasCRM() {
    return Boolean(this.enabledCRM)
  }

  constructor(props) {
    makeObservable(this, {
      integrationSync: observable,
      integrations: observable,
      installed: computed,
      disabled: computed,
      enabled: computed,
      enabledCRM: computed,
      setIntegrations: action.bound,
    })

    observe(this, 'enabledCRM', (c) => updateHelpHero({ enabledCRM: c.newValue }), true)
  }

  /**
   * Add integrations from login response
   * @param {*} integrations
   */
  setIntegrations(integrations = [], syncs = []) {
    this.integrationSync = syncs

    const ints = integrations.map((i) => {
      const name = i.identifier
      const Integration = require(`./${name}`).default
      const intg = { ...Integration, ...i }
      this[name] = intg
      return intg
    })

    this.integrations.replace(ints)
  }

  /**
   *
   */
  getIntegration = (identifier) => {
    return find(this.integrations, { identifier })
  }
}

export default Integrations
