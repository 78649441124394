import connection from '../connection'
import Connect from './connect'
import Icon from './icon'

const config = {
  ...connection,
  Connect,
  Icon,
}

export default config
