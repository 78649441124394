import React from 'react'
import PropTypes from 'prop-types'
import full from './full_logo.png'
import logo from './logo.png'

const PreciseFP = (props) => {
  const extraProps = {}
  if (props.png) {
    return <img src={props.png === 'full' ? full : logo} alt="" />
  }
  if (props.height) {
    extraProps.height = `${props.height}px`
  } else if (props.width) {
    extraProps.width = `${props.width}px`
  }
  return (
    <div {...props}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 240 96"
        // eslint-disable-next-line
        // style="enable-background:new 0 0 240 96;"
        xmlSpace="preserve"
      >
        <g id="Target">
          <path
            fill="#7BA835"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.9,45.5c0.3,1.6-0.3,3-1.3,3.3c-1.1,0.2-2.3-1-2.6-2.6c-0.3-1.7,0.4-3.1,1.5-3.2
		C164.5,42.8,165.6,44,165.9,45.5L165.9,45.5L165.9,45.5z"
          />
          <path
            fill="#7BA835"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M161.1,30.6c-6.5-0.7-11.8,6.7-10.3,17.3c1.6,11.5,9.8,18.1,16.4,14.9c5.3-2.6,7.4-10.8,5.7-18.4
		C171.3,37.2,166.4,31.2,161.1,30.6L161.1,30.6L161.1,30.6z M162.2,36.6c3.3,0,6.4,3.7,7.4,8.2c1,4.8-0.5,9.6-3.8,10.7
		c-3.7,1.3-8-2.6-9-8.6C155.9,41.1,158.5,36.6,162.2,36.6L162.2,36.6z"
          />
          <path
            fill="#7BA835"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.8,18.7c-2.8-0.8-5.6-1-8.4-0.4c-3,0.6-5.9,2.1-8.4,4.5c-2.9,2.6-5.2,6.3-6.9,10.8
		c-1.8,5.1-2.6,10.9-2.1,17.2c0.5,6.5,2.4,12.7,5.4,18.2c2.9,5.2,6.5,9.1,10.7,11.6c3.3,2,6.6,3,9.8,3h0.3c0.5,0,1,0,1.6-0.1
		c3.6-0.4,6.8-1.9,9.6-4.3c2.5-2.2,4.5-4.9,6.1-8.2c1.4-2.8,2.3-5.9,2.9-9.2c0.5-3,0.7-6.2,0.6-9.3c-0.1-3.1-0.6-6.1-1.3-9.1
		c-0.7-2.9-1.7-5.7-2.9-8.3c-1.2-2.5-2.6-4.9-4.2-7c-1.7-2.2-3.5-4.1-5.6-5.7C163.8,20.7,161.4,19.5,158.8,18.7L158.8,18.7
		L158.8,18.7L158.8,18.7z M159.9,24.2c7.5,1.6,14,9.9,16.3,19.6c2.4,10.4,0,22.4-7.3,27.4c-10.3,6.9-24.2-2.6-26.4-22
		C140.5,32.2,149.9,22.1,159.9,24.2L159.9,24.2z"
          />
        </g>
        <polygon fill="#A29F9D" fillRule="evenodd" clipRule="evenodd" points="209.1,8.8 199.5,21.6 163.6,46.1 " />
        <polygon fill="#B2B3B3" fillRule="evenodd" clipRule="evenodd" points="210.9,19.8 199.4,21.6 163.6,46.1 " />
        <g id="FP_x0020_">
          <polygon
            fill="#7BA835"
            fillRule="evenodd"
            clipRule="evenodd"
            points="189.5,64.7 198.1,64.7 198.1,53.3 209,53.3 209,47.7 198.1,47.7 198.1,42.8 210.8,42.8 210.8,36.8
		189.5,36.8 	"
          />
          <path
            fill="#7BA835"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M215.4,64.7h8.6V54.4h4.7c3.5,0,6.1-0.8,7.8-2.4c1.7-1.6,2.5-3.8,2.5-6.6c0-2.7-0.8-4.9-2.3-6.3
		c-1.6-1.5-3.9-2.2-7-2.2h-14.3L215.4,64.7L215.4,64.7z M224,42.5h2.5c1.6,0,2.7,0.3,3.3,0.9c0.6,0.6,0.9,1.4,0.9,2.3
		c0,0.9-0.3,1.6-1,2.2c-0.7,0.6-1.9,0.9-3.5,0.9H224V42.5L224,42.5L224,42.5z"
          />
        </g>
      </svg>
    </div>
  )
}

PreciseFP.propTypes = {
  png: PropTypes.oneOf([false, 'logo', 'full']),
}

PreciseFP.defaultProps = {
  png: false,
  width: 60,
}

export default PreciseFP
