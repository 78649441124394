import { SmartFieldDrawer } from 'components/drawers/Smartfields'
import UpgradePlanModal from 'components/modals/UpgradePlan'
import AddTag from './modals/AddTag'
import ImageUpload from './modals/ImageUpload'
import { SendEmail } from './modals/SendEmail'
import { ReplaceSmartFields } from './routes/tasks/modals/ReplaceSmartFields'

export const AppModalsAndDrawers = () => (
  <>
    <SmartFieldDrawer />
    <ReplaceSmartFields />
    <SendEmail />
    <ImageUpload />
    <AddTag />
    <UpgradePlanModal />
  </>
)
