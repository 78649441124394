import React from 'react'
import PropTypes from 'prop-types'
import full from './full_logo.png'
import logo from './logo.png'

const Redtail = (props) => {
  const extraProps = {}
  if (props.png) {
    return <img src={props.png === 'full' ? full : logo} alt="" />
  }
  if (props.height) {
    extraProps.height = `${props.height}px`
  } else {
    extraProps.width = `${props.width}px`
  }
  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" {...extraProps} viewBox="0 0 140 140">
        <path
          d=" M 134 70 C 134 105.346 105.345 134 70 134 C 34.659 134 6 105.346 6 70 C 6 34.653 34.659 6 70 6 C 105.345 6 134 34.653 134 70 Z "
          fill="none"
          stroke="none"
        />
        <path
          d=" M 134 70 C 134 105.346 105.345 134 70 134 C 34.659 134 6 105.346 6 70 C 6 34.653 34.659 6 70 6 C 105.345 6 134 34.653 134 70 Z "
          fill="none"
          vectorEffect="non-scaling-stroke"
          strokeWidth="12"
          stroke="rgb(179,40,45)"
          strokeLinejoin="round"
          strokeLinecap="butt"
          strokeMiterlimit="10"
        />
        <path
          d=" M 39.87 105.254 L 39.991 105.254 C 41.077 105.254 42.399 104.481 42.915 103.525 L 45.511 94.15 C 46.038 93.195 49.349 84.072 50.275 83.504 L 50.433 83.404 L 52.718 100.109 C 53.017 101.152 54.062 102.013 55.046 102.013 C 56.023 102.013 56.769 101.117 56.696 100.038 L 56.426 96.159 C 56.35 95.081 57.53 78.511 62.334 78.511 C 63.979 78.511 76.467 85.618 76.467 85.618 C 77.561 85.659 78.264 89.526 79.307 92.766 L 82.09 107.637 C 82.186 108.715 83.065 109.606 84.037 109.606 C 85.014 109.606 85.933 108.727 86.086 107.655 L 86.854 88.296 C 87.002 87.212 87.95 86.017 88.969 85.63 L 89.959 85.249 C 90.973 84.868 90.99 86.819 91.219 87.88 L 95.127 103.015 C 95.35 104.081 96.305 104.949 97.248 104.949 C 98.182 104.949 98.908 104.064 98.861 102.98 L 94.277 63.168 C 94.23 62.08 94.455 60.406 94.787 59.449 C 95.115 58.488 98.123 53.582 99.187 53.371 L 105.486 53.881 C 106.548 53.664 107.941 52.768 108.568 51.877 L 110.771 48.785 C 111.41 47.904 111.087 46.865 110.064 46.484 L 100.279 43.527 C 99.263 43.152 98.338 40.293 93.521 39.994 C 92.753 40.074 89.486 40.726 88.623 41.262 C 85.711 36.729 84.738 42.235 81.504 45.471 C 78.588 49.68 79.752 49.282 86.139 48.485 C 85.26 50.471 84.053 52.85 82.729 54.544 C 79.243 58.974 75.592 58.384 73.905 59.355 C 72.493 60.175 56.948 59.978 51.915 61.769 C 51.815 61.787 51.706 61.816 51.593 61.857 C 51.593 61.857 38.697 56.697 39.417 37.058 C 39.417 37.058 38.122 36.087 35.855 43.529 C 35.855 43.529 35.738 46.5 32.345 45.433 C 30.247 44.777 41.468 52.82 34.519 53.259 C 34.519 53.259 40.015 58.909 37.994 58.909 C 35.973 58.909 50.16 64.427 47.867 65.481 C 45.576 66.53 38.492 103.374 38.492 103.374 C 38.163 104.41 38.784 105.254 39.87 105.254 Z "
          fill="rgb(179,40,45)"
        />
      </svg>
    </div>
  )
}

Redtail.propTypes = {
  png: PropTypes.oneOf([false, 'logo', 'full']),
}

Redtail.defaultProps = {
  png: false,
  width: 60,
}

export default Redtail
