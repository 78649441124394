export const STATES = {
  // Integration states
  // 1. Hidden - not visible to the app
  HIDDEN: 'HIDDEN',
  // 2. Disabled - visible but not turned on
  DISABLED: 'DISABLE',
  // 3. Enabled - connected but not initial sync not complete
  ENABLED: 'ENABLE',
  // 4. Syncing - initially syncd and performing regular sync
  SYNCING: 'SYNCING',
  // 5. Enabled - but is not a syncing integration
  NON_SYNCING: 'NON_SYNCING',
}

export const isEnabled = (state) => {
  return state !== STATES.HIDDEN && state !== STATES.DISABLED && state !== STATES.NON_SYNCING
  // return state === STATES.ENABLED
}

export const CATEGORIES = {
  DATA_CONNECTOR: 'Data Connectors',
  DATA_IMPORT: 'Data Import',
  FILE_MANAGEMENT: 'File Management',
  CALENDARS: 'Calendars',
}

export const TABLE_TYPE = {
  INTERNAL: 'INTERNAL',
  SEPARATE: 'SEPARATE',
  NA: 'NA',
}
