import { Form, Input } from 'antd'
import Connect from '../connect'

const WealthboxConnect = (props) => (
  <Connect
    learnMoreLink="https://www.wealthbox.com/"
    {...props}
    features={['Sync Contacts', 'Sync Events']}
    renderSettings={(form, submit) => (
      <Form onFinish={submit} form={form}>
        <Form.Item label="API Key" name="key1" rules={[{ required: true, message: 'Enter consumer key!' }]}>
          <Input size="large" autoFocus />
        </Form.Item>
      </Form>
    )}
  />
)

export default WealthboxConnect
