export const STATES = {
  // Connection states
  // 1. Hidden - not visible to the app
  HIDDEN: 'HIDDEN',
  // 2. Disconnected
  DISCONNECTED: 'DISCONNECTED',
  // 3. Re-authenticate the connection
  REAUTHENTICATE: 'REAUTHENTICATE',
  // 4. Connected
  CONNECTED: 'CONNECTED',
}

export const TYPES = {
  CRM: 'CRM',
  INSIGHT: 'INSIGHT',
}

export const CATEGORIES = {
  CRM: 'Customer Relationship Management (CRM)',
  INSIGHT: 'Insight',
}

export const PROVIDERS = {
  RISKALYZE: 'riskalyze',
  PRECISEFP: 'precisefp',
  HOLISTIPLAN: 'holistiplan',
}

export const isConnected = (state) => {
  return state === STATES.CONNECTED
}

export const isDisconnected = (state) => {
  return state === STATES.DISCONNECTED
}

export const requiresAuth = (state) => {
  return state === STATES.REAUTHENTICATE
}
