import { Alert, Button, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { msg } from 'stores/msg'
import styled from 'styled-components'
import { FlexCol, FlexRow } from 'styles'
import { captureExceptionSilently } from '../../helpers/sentry'

const SectionsContainer = styled(FlexCol)``

const Section = styled.div`
  width: 50%;
`

const SectionTitle = styled.h3`
  font-weight: bold;
`

const SectionList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const ActivitiesCheckboxes = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  min-width: 150px;
  margin-left: 20px;
`

const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`

export const ActivitiesConfiguration = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const [activityTypes, setActivityTypes] = useState([])
  const [selectedActivityTypesIds, setSelectedActivityTypesIds] = useState([])
  const [initialSelectedActivityTypesIds, setInitialSelectedActivityTypesIds] = useState([])

  useEffect(() => {
    const loadAppointmentTypes = async () => {
      try {
        global.app.loading = true
        await global.data.appointmentTypes.loadAppointmentTypesExternal()

        const { appointmentTypes, appointmentTypesExternal: crmAppointmentTypes } = global.data.appointmentTypes

        const parsedActivities = parseActivityTypesData(crmAppointmentTypes)
        setActivityTypes(parsedActivities)
        const crmAppointmentTypesIds = crmAppointmentTypes.map(({ id }) => id)

        const selectedActivityTypesIds = appointmentTypes
          .filter(({ externalId }) => crmAppointmentTypesIds.includes(+externalId))
          .map(({ externalId }) => +externalId)

        setSelectedActivityTypesIds(selectedActivityTypesIds)
        setInitialSelectedActivityTypesIds(selectedActivityTypesIds)
        setIsLoaded(true)
      } catch (error) {
        msg.error('There was an error loading this screen.')
        captureExceptionSilently(error, { message: 'loadAppointmentsType' })
      } finally {
        global.app.loading = false
      }
    }

    loadAppointmentTypes()
  }, [])

  const parseActivityTypesData = (activityTypesArray) =>
    activityTypesArray.map(({ name, id }) => ({
      label: name,
      value: id,
    }))

  const onChange = (selectedActivityTypeIds) => {
    setSelectedActivityTypesIds(selectedActivityTypeIds)
  }

  const save = async () => {
    const payload = activityTypes.map(({ value, label }) => {
      return {
        externalId: value,
        name: label,
        enabled: selectedActivityTypesIds.includes(value),
      }
    })
    try {
      global.app.loading = true
      await global.data.appointmentTypes.updateAppointmentTypes(payload)
      setInitialSelectedActivityTypesIds(selectedActivityTypesIds)
      global.data.appt.clearCache()
      msg.success('Configuration Saved!')
      window.location.reload()
    } catch (error) {
      msg.error('There was an error saving.')
      console.error('err', error)
      captureExceptionSilently(error, { message: 'saveAppointmentTypes', data: { payload } })
    } finally {
      global.app.loading = false
    }
  }

  const cancel = () => {
    setSelectedActivityTypesIds(initialSelectedActivityTypesIds)
  }

  const areChangesPendingSave = !(
    initialSelectedActivityTypesIds.length === selectedActivityTypesIds.length &&
    initialSelectedActivityTypesIds.every((item) => selectedActivityTypesIds.includes(item))
  )

  return (
    <>
      <SectionsContainer>
        <FlexRow>
          <Alert
            style={{ marginBottom: 20, width: '100%' }}
            message="Select Activities to synchronize with Pulse360 from Redtail"
            description="If you unselect an activity type, we will hide those events in Pulse360 and any notes that you may have entered."
            type="info"
            showIcon
            banner
          />
        </FlexRow>

        <FlexRow>
          <Section>
            <SectionTitle>Activity Type</SectionTitle>
            <SectionList>
              {isLoaded && (
                <ActivitiesCheckboxes
                  options={activityTypes}
                  onChange={onChange}
                  value={selectedActivityTypesIds}
                ></ActivitiesCheckboxes>
              )}
            </SectionList>
          </Section>
        </FlexRow>
      </SectionsContainer>

      <ButtonsContainer>
        <StyledButton type="minimal" size="default" onClick={cancel} disabled={!areChangesPendingSave}>
          Cancel
        </StyledButton>
        <StyledButton type="primary" size="default" onClick={save} disabled={!areChangesPendingSave}>
          Save and Reload
        </StyledButton>
      </ButtonsContainer>
    </>
  )
}

ActivitiesConfiguration.propTypes = {}
