import React from 'react'
import PropTypes from 'prop-types'
import wealthbox from 'assets/images/integrations/wealthbox-logo-dark.png'
import full from './full_logo.png'
import logo from './logo.png'

const Wealthbox = (props) => {
  const extraProps = {}
  if (props.png) {
    return <img src={props.png === 'full' ? full : logo} alt="" />
  }
  if (props.height) {
    extraProps.height = `${props.height}px`
  } else {
    extraProps.width = `${props.width}px`
  }
  return (
    <div {...props}>
      <img src={wealthbox} {...extraProps} alt="" />
    </div>
  )
}

Wealthbox.propTypes = {
  png: PropTypes.oneOf([false, 'logo', 'full']),
}

Wealthbox.defaultProps = {
  png: false,
  width: 256,
}

export default Wealthbox
