/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SmartFieldConfig, SmartFieldIntegrationConfig } from 'components/drawers/Smartfields/types/data'
import { RiskalyzeConfig } from 'components/drawers/Smartfields/types/integration'
import { Note } from 'types/graphql'
import { ProviderSmartFieldsProps } from '../provider.smartfields'
import { RiskalyzeDataSmartFields } from './types/data/data.smartfields'
import { RiskalyzeQuestionaireSmartFields } from './types/questionaire/questionaire.smartfields'

interface RiskalyzeSmartFieldsProps extends ProviderSmartFieldsProps {
  note: Note
  config: SmartFieldConfig<SmartFieldIntegrationConfig<RiskalyzeConfig>>
}

export const RiskalyzeSmartFields: React.FC<RiskalyzeSmartFieldsProps> = ({ form, config, uid, setAsyncLoading }) => {
  return (
    <>
      {config.dataType === 'questionaire' ? (
        <RiskalyzeQuestionaireSmartFields uid={uid} form={form} config={config} setAsyncLoading={setAsyncLoading} />
      ) : (
        <RiskalyzeDataSmartFields uid={uid} form={form} config={config} setAsyncLoading={setAsyncLoading} />
      )}
    </>
  )
}
