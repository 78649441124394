/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, Select } from 'antd'
import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import { HolistiplanConfig } from 'components/drawers/Smartfields/types/integration'
import { isEmpty } from 'lodash'
import { autorun } from 'mobx'
import { useCallback, useEffect } from 'react'
import { Note } from 'types/graphql'
import { FormItemLabel } from '../../../smartfield.styles'
import {
  ProviderSmartFields,
  ProviderSmartFieldsProps,
} from '../provider.smartfields'
import { useHolistiplanData } from './holistiplan.hooks'

interface HolistiplanSmartFieldsProps extends ProviderSmartFieldsProps {
  note: Note
  config: SmartFieldConfig<SmartFieldIntegrationConfig<HolistiplanConfig>>
}

export const HolistiplanSmartFields: React.FC<HolistiplanSmartFieldsProps> = ({
  form,
  config,
  uid,
}) => {
  const {
    loadingRecords,
    loadingClients,
    clients,
    taxRecords,
    error,
    onSelectClient,
    onSelectInputYear,
  } = useHolistiplanData(config as HolistiplanConfig, form, uid)

  const onChange = useCallback((clientId: string) => {
    global.holistiplan._clientId = clientId
  }, [])

  useEffect(() => {
    return autorun(() => {
      const client = form.getFieldValue('client')
      const { _clientId } = global.holistiplan

      if (clients.length && isEmpty(client) && _clientId) {
        form.setFieldValue('client', _clientId)
        onSelectClient(_clientId)
      }
    })
  }, [clients, form, onSelectClient])

  return (
    <ProviderSmartFields
      form={form}
      uid={uid}
      error={error}
      // label={config?.label}
      data-testid="replace-smartfield-date"
      showOnError
      renderOnError={() => (
        <Form.Item
          name="client"
          label={
            <FormItemLabel
              label="Holistiplan Contact"
              description="Select the required Holistplan contact"
            />
          }
          initialValue={
            clients.length > 0 && !isEmpty(global.holistiplan?._clientId)
              ? global.holistiplan?._clientId
              : undefined
          }
        >
          <Select
            onChange={onChange}
            placeholder={
              loadingClients ? 'Fetching contacts..' : 'Select a contact'
            }
            disabled={loadingClients}
            style={{ width: '100%' }}
            loading={loadingClients}
            size="large"
            showSearch
            filterOption={(input, option) =>
              (`${option?.label}` ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {clients.map((client) => (
              <Select.Option
                value={client.id}
                label={`${client.first_name} ${client.last_name}`}
              >
                {`${client.first_name} ${client.last_name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    >
      <Form.Item
        name="year"
        label={
          <FormItemLabel
            label="Holistiplan Tax Records"
            description="Select the required tax record"
          />
        }
      >
        <Select
          onChange={(val: number) => onSelectInputYear(val)}
          placeholder={
            loadingRecords ? 'Loading records..' : 'Select input year'
          }
          style={{ width: '100%' }}
          disabled={!taxRecords?.length}
          loading={loadingRecords}
          size="large"
        >
          {taxRecords.map((taxRecord) => (
            <Select.Option value={taxRecord.id}>
              {taxRecord.input_year}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </ProviderSmartFields>
  )
}
