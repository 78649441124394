import PropTypes from 'prop-types'
import full from './full_logo.png'
import logo from './logo.png'

const Redtail = (props) => {
  const extraProps = {}
  if (props.png) {
    return <img src={props.png === 'full' ? full : logo} alt="" />
  }
  if (props.height) {
    extraProps.height = `${props.height}px`
  } else {
    extraProps.width = `${props.width}px`
  }
  // return (
  //   <div {...props}>
  //     <svg xmlns="http://www.w3.org/2000/svg" {...extraProps} viewBox="0 0 140 140">
  //       <path
  //         d=" M 134 70 C 134 105.346 105.345 134 70 134 C 34.659 134 6 105.346 6 70 C 6 34.653 34.659 6 70 6 C 105.345 6 134 34.653 134 70 Z "
  //         fill="none"
  //         stroke="none"
  //       />
  //       <path
  //         d=" M 134 70 C 134 105.346 105.345 134 70 134 C 34.659 134 6 105.346 6 70 C 6 34.653 34.659 6 70 6 C 105.345 6 134 34.653 134 70 Z "
  //         fill="none"
  //         vectorEffect="non-scaling-stroke"
  //         strokeWidth="12"
  //         stroke="rgb(179,40,45)"
  //         strokeLinejoin="round"
  //         strokeLinecap="butt"
  //         strokeMiterlimit="10"
  //       />
  //       <path
  //         d=" M 39.87 105.254 L 39.991 105.254 C 41.077 105.254 42.399 104.481 42.915 103.525 L 45.511 94.15 C 46.038 93.195 49.349 84.072 50.275 83.504 L 50.433 83.404 L 52.718 100.109 C 53.017 101.152 54.062 102.013 55.046 102.013 C 56.023 102.013 56.769 101.117 56.696 100.038 L 56.426 96.159 C 56.35 95.081 57.53 78.511 62.334 78.511 C 63.979 78.511 76.467 85.618 76.467 85.618 C 77.561 85.659 78.264 89.526 79.307 92.766 L 82.09 107.637 C 82.186 108.715 83.065 109.606 84.037 109.606 C 85.014 109.606 85.933 108.727 86.086 107.655 L 86.854 88.296 C 87.002 87.212 87.95 86.017 88.969 85.63 L 89.959 85.249 C 90.973 84.868 90.99 86.819 91.219 87.88 L 95.127 103.015 C 95.35 104.081 96.305 104.949 97.248 104.949 C 98.182 104.949 98.908 104.064 98.861 102.98 L 94.277 63.168 C 94.23 62.08 94.455 60.406 94.787 59.449 C 95.115 58.488 98.123 53.582 99.187 53.371 L 105.486 53.881 C 106.548 53.664 107.941 52.768 108.568 51.877 L 110.771 48.785 C 111.41 47.904 111.087 46.865 110.064 46.484 L 100.279 43.527 C 99.263 43.152 98.338 40.293 93.521 39.994 C 92.753 40.074 89.486 40.726 88.623 41.262 C 85.711 36.729 84.738 42.235 81.504 45.471 C 78.588 49.68 79.752 49.282 86.139 48.485 C 85.26 50.471 84.053 52.85 82.729 54.544 C 79.243 58.974 75.592 58.384 73.905 59.355 C 72.493 60.175 56.948 59.978 51.915 61.769 C 51.815 61.787 51.706 61.816 51.593 61.857 C 51.593 61.857 38.697 56.697 39.417 37.058 C 39.417 37.058 38.122 36.087 35.855 43.529 C 35.855 43.529 35.738 46.5 32.345 45.433 C 30.247 44.777 41.468 52.82 34.519 53.259 C 34.519 53.259 40.015 58.909 37.994 58.909 C 35.973 58.909 50.16 64.427 47.867 65.481 C 45.576 66.53 38.492 103.374 38.492 103.374 C 38.163 104.41 38.784 105.254 39.87 105.254 Z "
  //         fill="rgb(179,40,45)"
  //       />
  //     </svg>
  //   </div>
  // )

  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" {...extraProps} viewBox="0 0 194 49" fill="none">
        <g clip-path="url(#clip0_536_2779)">
          <path
            d="M70.6067 14.0933V28.0352L57.9188 10.9189H51.719L54.4708 14.0933V32.8666L52.7569 36.5402H59.5527L56.8009 33.3658V16.2096L72.9435 37.6182V14.5924L74.6507 10.9189H67.8548L70.6067 14.0933Z"
            fill="#6941C7"
          />
          <path
            d="M82.5978 17.6348H75.2798L78.6409 21.3615V32.9343L76.9604 36.541H85.2829L82.5978 33.4666V17.6348Z"
            fill="#6941C7"
          />
          <path
            d="M80.6033 14.9266C81.9825 14.9266 82.9804 13.9149 82.9804 12.5304C82.9804 11.146 81.956 10.1343 80.6033 10.1343C79.2506 10.1343 78.2258 11.166 78.2258 12.5304C78.2258 13.8949 79.2237 14.9266 80.6033 14.9266Z"
            fill="#6941C7"
          />
          <path
            d="M97.0934 33.6662C96.5511 34.2053 95.8815 34.4648 95.0445 34.4648C93.9266 34.4648 92.6409 34.0855 92.6409 31.1174V20.2235H97.0399V17.6281H92.6409V11.7119H89.9159L88.7708 17.6347H85.1084V20.2301H88.6438V31.6698C88.6438 34.9839 90.4514 36.9605 93.4779 36.9605C95.5867 36.9605 97.1203 36.0352 97.9035 34.2851L98.0039 34.0589L97.3075 33.4533L97.0865 33.6729L97.0934 33.6662Z"
            fill="#6941C7"
          />
          <path
            d="M110.418 17.5625C107.947 17.5625 105.885 19.2196 104.727 22.0079V17.6357H97.7231L101.084 21.369V32.9418L99.4037 36.5487H107.726L105.041 33.4742V26.3402C105.718 23.6517 106.782 22.0478 108.181 21.5953C108.603 21.4888 108.985 21.5621 109.42 21.6486C109.741 21.7085 110.076 21.775 110.458 21.775C112.004 21.775 113.009 20.9565 113.009 19.6854C113.009 18.4143 112.018 17.5625 110.424 17.5625H110.418Z"
            fill="#6941C7"
          />
          <path
            d="M123.266 16.9766C117.233 16.9766 113.336 20.9429 113.336 27.0853C113.336 33.2277 117.139 36.9544 123.266 36.9544C129.393 36.9544 133.229 33.1479 133.229 27.0187C133.229 20.8896 129.318 16.9766 123.266 16.9766ZM123.266 34.6385C118.532 34.6385 117.541 30.4926 117.541 27.0187C117.541 22.0875 119.63 19.2592 123.266 19.2592C126.902 19.2592 129.064 22.0875 129.064 27.0187C129.064 31.95 127.009 34.6385 123.266 34.6385Z"
            fill="#6941C7"
          />
          <path
            d="M142.742 16.9766C138.043 16.9766 135.016 19.5786 135.016 23.5715C135.016 26.2202 136.315 28.3031 138.625 29.4211C137.118 29.9535 136.302 31.0316 136.302 32.5223C136.302 33.5471 136.723 34.399 137.5 34.9913C135.525 35.69 134.293 37.3471 134.293 39.3702C134.293 42.518 137.179 44.248 142.421 44.248C148.574 44.248 152.109 41.9323 152.109 37.8926C152.109 34.765 149.994 33.0015 145.816 32.6421L141.524 32.2295C140.988 32.1762 139.75 31.9766 139.75 31.2845C139.75 30.7055 140.741 30.3794 142.468 30.3794C147.503 30.3461 150.63 27.7374 150.63 23.5715C150.63 19.8448 147.751 16.9766 142.763 16.9766H142.742ZM142.897 19.0196C145.26 19.0196 146.613 20.67 146.613 23.5383C146.613 26.4065 145.213 28.3364 142.863 28.3364C140.513 28.3364 138.939 26.4531 138.939 23.5383C138.939 20.6235 140.419 19.0196 142.897 19.0196ZM142.481 42.0722C139.047 42.0722 137.379 41.0673 137.379 38.9976C137.379 37.3672 138.35 36.4487 140.526 36.0295L145.187 36.4419C148.24 36.7414 148.588 37.926 148.588 38.7978C148.588 40.8209 146.244 42.0786 142.481 42.0786V42.0722Z"
            fill="#6941C7"
          />
          <path
            d="M169.779 31.2978C167.61 33.4872 166.01 34.2592 163.646 34.2592C159.562 34.2592 157.165 31.8102 156.864 27.3515H170.141V27.0254C170.208 23.4584 169.371 20.7765 167.67 19.0529C166.311 17.6753 164.443 16.9766 162.127 16.9766C156.569 16.9766 152.686 21.2024 152.686 27.2583C152.686 33.3142 156.341 36.9544 161.992 36.9544C165.829 36.9544 168.815 35.2175 170.63 31.93L170.764 31.6838L169.994 31.0849L169.786 31.2978H169.779ZM161.986 19.2659C164.858 19.2659 166.351 21.2756 166.425 25.2419H156.837C157.158 21.4353 159.02 19.2659 161.986 19.2659Z"
            fill="#6941C7"
          />
          <path
            d="M185.139 36.5416H193.394L190.783 33.5401V24.7158C190.783 19.7513 188.607 17.0161 184.663 17.0161C181.791 17.0161 179.4 18.4602 177.827 21.1222V17.635H170.823L174.185 21.3684V32.9412L172.504 36.548H180.827L178.142 33.4736V24.9021C179.039 21.8741 181.088 19.9908 183.485 19.9908C185.708 19.9908 186.785 21.5681 186.785 24.8223V33.0476L185.139 36.5548V36.5416Z"
            fill="#6941C7"
          />
          <path d="M152.673 16.4053V20.3323H147.985L151.514 16.4053H152.673Z" fill="#6941C7" />
          <path
            d="M26.5913 9.02973V3.01025L21.3783 0.000488281L16.1653 3.01025V9.02973L21.3783 6.01997L26.5913 9.02973Z"
            fill="#6941C7"
          />
          <path
            d="M16.1653 39.1572V45.1767L21.3783 48.1864L26.5913 45.1767V39.1572L21.3783 42.1669L16.1653 39.1572Z"
            fill="#6941C7"
          />
          <path
            d="M5.72595 15.0605L10.939 12.0508L5.72595 9.04102L0.512939 12.0508V18.0703L5.72595 21.08V15.0605Z"
            fill="#6941C7"
          />
          <path
            d="M37.0294 33.1333L31.8164 36.143L37.0294 39.1528L42.2424 36.143L42.2425 30.1235L37.0294 27.1138V33.1333Z"
            fill="#6941C7"
          />
          <path
            d="M5.72864 27.1157L0.515625 30.1255V36.145L5.72864 39.1547L10.9417 36.145L5.72864 33.1352V27.1157Z"
            fill="#6941C7"
          />
          <path
            d="M37.0324 9.04297L31.8193 12.0527L37.0324 15.0625L37.0324 21.0819L42.2454 18.0722V12.0527L37.0324 9.04297Z"
            fill="#6941C7"
          />
          <path
            d="M16.1653 27.1084V33.1279L21.3783 36.1376L26.5913 33.1279V27.1084L21.3783 30.1182L16.1653 27.1084Z"
            fill="#6941C7"
          />
          <path
            d="M21.3784 18.0781L26.5914 21.0878V27.1074L31.8045 24.0976V18.0781L26.5914 15.0684L21.3784 18.0781Z"
            fill="#6941C7"
          />
          <path
            d="M21.3785 18.0781L16.1654 15.0684L10.9524 18.0781L10.9524 24.0976L16.1654 27.1074V21.0878L21.3785 18.0781Z"
            fill="#6941C7"
          />
        </g>
        <defs>
          <clipPath id="clip0_536_2779">
            <rect width="192.881" height="48.1851" fill="white" transform="translate(0.512939)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

Redtail.propTypes = {
  png: PropTypes.oneOf([false, 'logo', 'full']),
}

Redtail.defaultProps = {
  png: false,
  width: 60,
}

export default Redtail
