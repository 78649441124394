import { getQueryParams } from 'helpers/href'

/**
 * Available filter (Action) types - 'filter' query param
 * @see src/components/routes/tasks/actions/select.action.tsx
 */
export const FilterTypes = {
  AGENDA: 'agenda',
  SUMMARY: 'summary',
  NOTES: 'global',
  ALL_NOTES: 'all',
  HISTORY: 'history',
  APPTS: 'appts',
  AI_WRITER: 'ai_writer',
} as const

/**
 * Available apptId types - 'apptId' query param
 */
export const ApptTypes = {
  // SearchAll only, adds "All Appointments" option to event selector
  ALL: 'all',
  // shows QuickNotes
  GLOBAL: 'global',
  // Agenda/Summary only, gets soonest appointment, fallsback to GlobalAppt
  NEAREST: 'nearest',
}

/**
 * Different view states of the preview editor on notes pages
 */
export const EditorViews = {
  // no editor shown - DELETE denotes the query param is removed
  HIDDEN: 'DELETE',
  // shows small editor along-side notes, in preview mode
  PREVIEW: 'preview',
  // shows small editor along-side notes, in template mode
  TEMPLATE: 'template',
  // shows full size editor, in editor mode (hides notes)
  EDIT: 'edit',
}

type Editor = string | null | undefined

export interface EditorPreset {
  userId: string
  filter: string
  editor: Editor
  category?: number
  template?: number
  apptId?: any
}

export const EditorPresets = (preset: string): EditorPreset => {
  const { userId, template, category, apptId, editor } = getQueryParams()

  const fallbackUserId = global.data.appt.appointment?.clientId

  const presets = {
    [FilterTypes.AGENDA]: {
      userId: (userId || fallbackUserId) as string,
      filter: FilterTypes.AGENDA,
      editor: editor ?? EditorViews.PREVIEW,
      category: category ?? 2,
      template: template,
      apptId: apptId ?? ApptTypes.NEAREST,
    },
    [FilterTypes.SUMMARY]: {
      userId: (userId || fallbackUserId) as string,
      filter: FilterTypes.SUMMARY,
      editor: editor ?? EditorViews.PREVIEW,
      category: category ?? 1,
      template: template,
      apptId: apptId ?? ApptTypes.NEAREST,
    },
    [FilterTypes.NOTES]: {
      userId: (userId || fallbackUserId) as string,
      filter: FilterTypes.NOTES,
      editor: editor ?? EditorViews.HIDDEN,
      apptId: apptId ?? ApptTypes.GLOBAL,
    },
    [FilterTypes.ALL_NOTES]: {
      userId: (userId || fallbackUserId) as string,
      filter: FilterTypes.ALL_NOTES,
      editor: editor ?? EditorViews.HIDDEN,
      apptId: ApptTypes.ALL,
    },
    [FilterTypes.HISTORY]: {
      userId: (userId || fallbackUserId) as string,
      filter: FilterTypes.HISTORY,
      editor: editor ?? EditorViews.HIDDEN,
      apptId: apptId ?? ApptTypes.ALL,
    },
    [FilterTypes.APPTS]: {
      userId: (userId || fallbackUserId) as string,
      filter: FilterTypes.APPTS,
      editor: editor ?? EditorViews.HIDDEN,
      apptId: apptId ?? ApptTypes.GLOBAL,
    },
    [FilterTypes.AI_WRITER]: {
      userId: (userId || fallbackUserId) as string,
      filter: FilterTypes.AI_WRITER,
      editor: editor ?? EditorViews.HIDDEN,
      apptId: apptId ?? ApptTypes.NEAREST,
    },
  }

  const presetCast = preset as keyof typeof presets

  return presets[presetCast] ?? (presets[FilterTypes.NOTES] as EditorPreset)
}
