import { SmartFieldConfig, SmartFieldIntegrationConfig } from 'components/drawers/Smartfields/types/data'
import {
  HolistiplanFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import { HolistiplanConfig } from 'components/drawers/Smartfields/types/integration'

export const holistiplanParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldConfig => {
  const dateValues = values as SmartFieldFormValues<SmartFieldIntegrationFormValues<HolistiplanFormValues>>
  const newConfig = config as SmartFieldConfig<SmartFieldIntegrationConfig<HolistiplanConfig>>

  newConfig.provider = 'holistiplan'
  newConfig.field = dateValues.field

  return newConfig as SmartFieldConfig
}

export const holistiplanConfigParser = (
  values: SmartFieldIntegrationFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldIntegrationFormValues<HolistiplanFormValues> => {
  const intgrValues = values as SmartFieldIntegrationFormValues<HolistiplanFormValues>
  const intgrConfig = config as SmartFieldConfig<SmartFieldIntegrationConfig<HolistiplanConfig>>

  intgrValues.field = intgrConfig.field

  return intgrValues
}
