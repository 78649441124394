import { CalendarOutlined, FontSizeOutlined, MenuOutlined, NumberOutlined } from '@ant-design/icons'
import { SmartFieldType } from 'components/drawers/Smartfields/types'
import { CSSProperties } from 'react'
import styled from 'styled-components'
import { AtomConnection } from './atom.hooks'

interface AtomIconsProps {
  type: SmartFieldType
  connection?: AtomConnection
  style?: CSSProperties
}

const defaultStyle = { fontSize: 10, marginRight: 2 }

const AtomConnectionLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  height: 12px;
  width: 12px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

export const SmartFieldAtomIcons: React.FC<AtomIconsProps> = ({ type, connection, style: atomStyle = {} }) => {
  const style = { ...defaultStyle, ...atomStyle }
  return (
    <>
      {type === 'date' && <CalendarOutlined style={style} data-testid="date-icon" />}
      {type === 'list' && <MenuOutlined style={style} data-testid="list-icon" />}
      {type === 'number' && <NumberOutlined style={style} data-testid="number-icon" />}
      {type === 'text' && <FontSizeOutlined style={style} data-testid="text-icon" />}
      {connection?.Icon && (
        <AtomConnectionLogo>
          <connection.Icon png="logo" />
        </AtomConnectionLogo>
      )}
    </>
  )
}
