import { SmartFieldConfig, SmartFieldIntegrationConfig } from 'components/drawers/Smartfields/types/data'
import {
  RiskalyzeFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import { RiskalyzeConfig } from 'components/drawers/Smartfields/types/integration'
import { riskalyzeModelConfigParser, riskalyzeModelParser } from './types/data/parser'
import { riskalyzeQuestionaireConfigParser, riskalyzeQuestionaireParser } from './types/questionaire/parser'

export const riskalyzeParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldConfig => {
  const riskalyzeValues = values as SmartFieldFormValues<SmartFieldIntegrationFormValues<RiskalyzeFormValues>>
  const newConfig = config as SmartFieldConfig<SmartFieldIntegrationConfig<RiskalyzeConfig>>

  newConfig.provider = 'riskalyze'
  newConfig.dataType = riskalyzeValues.dataType

  switch (riskalyzeValues.dataType) {
    case 'questionaire':
      config = riskalyzeQuestionaireParser(values, config)
      break
    default:
      config = riskalyzeModelParser(values, config)
      break
  }

  return newConfig as SmartFieldConfig
}

export const riskalyzeConfigParser = (
  values: SmartFieldIntegrationFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldIntegrationFormValues<RiskalyzeFormValues> => {
  const intgrValues = values as SmartFieldIntegrationFormValues<RiskalyzeFormValues>
  const intgrConfig = config as SmartFieldConfig<SmartFieldIntegrationConfig<RiskalyzeConfig>>

  switch (intgrConfig.dataType) {
    case 'questionaire':
      riskalyzeQuestionaireConfigParser(values, intgrConfig)
      break
    default:
      riskalyzeModelConfigParser(values, intgrConfig)
      break
  }

  return intgrValues
}
