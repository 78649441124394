import { mentionToSmartField } from 'components/drawers/Smartfields/smartfield.convertors'
import { multiUseRegex, sidOnlyRegex, singleUseRegex } from 'components/drawers/Smartfields/smartfield.regex'
import { SmartField, SmartFieldMentionAttrs } from 'components/drawers/Smartfields/types'
import { Span } from 'components/froala/util'
import { ABBR_DATE } from 'helpers/formats'
import { captureExceptionSilently } from 'helpers/sentry'
import { DATA_ATTRIB_ID } from 'lib/remirror/extensions/smartFieldAtom/constants'
import { htmlToRemirrorJSON } from 'lib/remirror/util/remirror.util'
import { isEmpty, isNil } from 'lodash'
import { isMoment } from 'moment'
import { smartfieldFactory as factory } from 'stores/smartfields/factory'
import { Note } from 'types/graphql'
import { v4 as uuidv4 } from 'uuid'
import { NoteReplacementValues } from '.'
import { SmartFieldKind } from './constants'

export const isActuallyEmpty = (val?: unknown) => {
  if (typeof val === 'string') {
    return isEmpty(val)
  }

  if (typeof val === 'number') {
    return isNaN(val)
  }

  return isNil(val)
}

export const MERFIELD_FIELD_REGEX = /{{(.*?)}}/
export const MERFIELD_FIELD_REGEX_GLOBAL = /{{(.*?)}}/g

export function createAtomAttrs(label: string) {
  return {
    id: uuidv4(),
    label,
    kind: SmartFieldKind.SMARTFIELD,
    reuse: 'false',
    config: JSON.stringify({ label, type: 'text' }),
    name: 'command',
  }
}

/**
 * @param token
 * @returns
 */
export const createDOMAttrs = (label: string) => {
  return {
    'data-mention-atom-id': uuidv4(),
    'data-mention-atom-name': 'command',
    'data-label': label,
    'data-reuse': 'false',
    'data-config': JSON.stringify({
      label: fixDollarSignInsert(label),
      type: 'text',
    }),
    'data-kind': SmartFieldKind.SMARTFIELD,
    'data-display-mode': 'inline',
  }
}

export const sanitiseLabel = (label: string): string => {
  return label
    .replace('{{', '')
    .replace('}}', '')
    .replace(/(<([^>]+)>)/gi, '')
}

/**
 * @param parent
 * @param child
 * @param content
 */
export const replace = (parent: ParentNode, child: Node, content: string) => {
  parent.insertBefore(Span(content), child)
  parent.removeChild(child)
}

/**
 * @param note
 * @param values
 * @param setText
 */
export const replaceNodes = (note: Note, values: NoteReplacementValues): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(note?.text as string, 'text/html')

  const selector = `[data-mention-atom-id]`
  const smartfields = Array.from(doc.querySelectorAll(selector))

  for (const smartfieldId in values) {
    let content = values[smartfieldId]

    // short-circuit if no value
    if (isActuallyEmpty(content)) {
      continue
    }

    if (isMoment(content)) {
      content = content.format(ABBR_DATE)
    }

    const smartfield = smartfields.find((mf) => {
      return mf.getAttribute('data-mention-atom-id') === smartfieldId
    })

    if (smartfield) {
      // update smartfield label in config object
      const cfg = JSON.parse(smartfield.getAttribute('data-config') as string)
      const config = JSON.stringify({ ...cfg, label: content })

      smartfield.setAttribute('data-config', config)

      const [child] = smartfield.childNodes
      replace(smartfield, child, content as string)
    }
  }

  return doc.body.innerHTML
}

/**
 * @param note
 * @param values
 */
export const replaceParentNodes = (note: Note, values: any): Document => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(note?.text as string, 'text/html')

  for (const field in values) {
    let content = values[field]

    if (isActuallyEmpty(content)) {
      continue
    }

    if (isMoment(content)) {
      content = content.format(ABBR_DATE)
    }

    replaceParentNode(doc, field, content)
  }

  return doc
}

/**
 * @param doc
 * @param nodeId
 * @param content
 * @returns
 */
export const replaceParentNode = (doc: Document, nodeId: string, content: string) => {
  const selector = `[data-mention-atom-id="${nodeId}"]`
  const smartfield = doc.querySelector(selector)

  if (smartfield) {
    const parent = smartfield.parentNode as ParentNode
    replace(parent, smartfield, content)
  }

  return doc.body.innerHTML
}

/**
 * @param note
 * @param values
 */
export const updateConfigAttr = (smartfield: SmartField): void => {
  const selector = `[data-mention-atom-id="${smartfield.id}"]`
  const element = document.querySelector(selector)

  if (element) {
    element.setAttribute('data-config', JSON.stringify(smartfield.config))
  }
}

/**
 * @param doc
 * @param note
 */
export const updateNote = async (doc: Document, note: Note) => {
  const text = doc.body.innerHTML
  const content = htmlToRemirrorJSON(text)

  note.text = text
  note.content = content

  const fields = { text, content }

  try {
    await global.data.appt.updateNote(note.id as number, fields)
  } catch (error) {
    captureExceptionSilently(error, {
      message: 'Error updating note',
      data: { id: note.id, fields },
    })
    throw error
  }
}

/**
 * @param note
 * @returns
 */
export const parseSmartFieldNodes = (note: Note): HTMLSpanElement[] => {
  if (!note) return []

  const parser = new DOMParser()
  const doc = parser.parseFromString(note.text as string, 'text/html')

  const nodeList = doc.querySelectorAll<HTMLSpanElement>(`[data-kind="${SmartFieldKind.SMARTFIELD}"]`)
  const nodes: HTMLSpanElement[] = [].slice.call(nodeList)

  return nodes
}

/**
 * @param note
 * @returns
 */
export const parseSmartFields = (nodes: HTMLSpanElement[]): SmartField[] => {
  return nodes.map((node) => {
    const attrs = Object.assign({}, node.dataset, { id: node.dataset.mentionAtomId }) as SmartFieldMentionAttrs
    const smartfield = factory.get(attrs.mentionAtomId as string)

    if (smartfield) {
      return smartfield
    }

    return mentionToSmartField(attrs)
  })
}

/**
 * @param note
 * @returns
 */
export const highlightActive = (editorRef: HTMLElement, activeSmartfield: SmartField): void => {
  const nodes = editorRef.querySelectorAll(`[data-mention-atom-id]`)
  // don't bother highlighting if only one smartfield
  if (nodes.length === 1) return

  for (const sf of Array.from(nodes)) {
    const id = sf.getAttribute(DATA_ATTRIB_ID)
    const isActive = id === activeSmartfield.id

    const func = isActive ? 'add' : 'remove'
    sf.classList[func]('active')
  }
}

/**
 * @param note
 * @returns
 */
export const updateSmartFields = (note: Note): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(note.text as string, 'text/html')

  const selector = `[data-mention-atom-id]`
  const elements: HTMLSpanElement[] = Array.from(doc.querySelectorAll(selector))

  const values = elements.reduce((values, sf) => {
    const smartfield = factory.get(sf.dataset.mentionAtomId as string)
    if (smartfield) {
      return { ...values, [smartfield.id]: smartfield.label }
    }

    return values
  }, {})

  return replaceNodes(note, values)
}

export const appendSid = (id: string, sid: string) => {
  return `${removeSid(id)}|${sid}`
}

export const extractSid = (id: string) => {
  return removeUuid(id)
}

export const removeSid = (id: string) => {
  return id.replace(/\|\d{1,}$/, '')
}

export const removeUuid = (id: string) => {
  return id.replace(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\|/, '')
}

export const formatSid = (id: string) => {
  const isUUID = singleUseRegex.test(id)
  const isSID = multiUseRegex.test(id)

  if (!isUUID && !isSID && sidOnlyRegex.test(id)) {
    return `${uuidv4()}|${id}`
  }

  return id
}

/**
 * @param text
 * @returns
 */
export const hasOldStyle = (text = '') => {
  return /{{(.*?)}}/.test(text)
}

/**
 * @param text
 * @returns
 */
export const hasNewStyle = (text = '') => {
  return text.includes(`data-kind="${SmartFieldKind.SMARTFIELD}"`)
}

/**
 * Adds a backslash to dollar signs to prevent them from being interpreted as special characters in a string.
 * @param text
 * @returns
 */
export const fixDollarSignInsert = (text: string) => {
  return text.replaceAll('$', '&dollar')
}

/**
 * Adds a backlashes to dollar signs and ampersands to prevent them from being interpreted as special characters in a string.
 * @param text
 * @returns
 */
export const preventMatchedSubstringInsert = (text: string) => {
  return text.replace('$&quot;', '&dollar;&quot;')
}
