import { msg } from 'stores/msg'
import { captureExceptionSilently } from '../../helpers/sentry'
import connectionService from '../services/connection.service'

/**
 * @param {*} fields
 */
const connect = async (fields, identifier) => {
  const connection = global.connections.getConnection(identifier)

  try {
    const authorizationUri = await connectionService.connect(
      fields.clientId,
      fields.clientSecret,
      window.location.href,
      identifier,
      connection.type,
    )
    document.location.replace(authorizationUri)
  } catch (err) {
    captureExceptionSilently(err, { message: 'connectConnection', data: { identifier, clientId: fields.clientId } })
    msg.error(err.message)
  }
}

/**
 * @param {*} fields
 */
const reconnect = async (identifier) => {
  const connection = global.connections.getConnection(identifier)
  try {
    const authorizationUri = await connectionService.reconnect(identifier, window.location.href, connection.type)
    document.location.replace(authorizationUri)
  } catch (err) {
    captureExceptionSilently(err, { message: 'reconnectConnection', data: { identifier } })
    msg.error(err.message)
  }
}

/**
 * @param {*} fields
 */
const connectCallback = async (authCode, identifier) => {
  const connection = global.connections.getConnection(identifier)
  const { origin, pathname } = window.location
  const redirectCallback = `${origin}${pathname}`

  try {
    const conenctionResponse = await connectionService.connectCallback(
      redirectCallback,
      identifier,
      authCode,
      connection.type,
    )

    if (conenctionResponse) {
      global.connections.updateConnection(identifier, conenctionResponse)
    }
  } catch (err) {
    captureExceptionSilently(err, { message: 'connectCallbackConnection', data: { identifier } })
    msg.error(err.message)
  }
}

/**
 * Disable the integration
 */
const disconnect = async (identifier) => {
  const connection = global.connections.getConnection(identifier)
  try {
    const removeConnectionResponse = await connectionService.removeCallback(identifier, connection.type)

    if (removeConnectionResponse) {
      global.connections.updateConnection(identifier, removeConnectionResponse)
    }
  } catch (err) {
    captureExceptionSilently(err, { message: 'disconnectConnection', data: { identifier } })
    msg.error(err.message)
  }
}

export default {
  connect,
  reconnect,
  connectCallback,
  disconnect,
}
