import integration from '../integration'
import Connect from './connect'
import Icon from './icon'

const salesforce = {
  ...integration,
  Connect,
  Icon,
}

export default salesforce
