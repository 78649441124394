import { htmlToRemirrorJSON, parseJSONToRemirror, remirrorJSONToHtml } from 'lib/remirror/util/remirror.util'
import { MERFIELD_FIELD_REGEX_GLOBAL, hasNewStyle, hasOldStyle } from 'stores/smartfields/utils'
import { BulkNoteFieldsFragment, Note, NoteFieldsFragment, Summary, SummaryFieldsFragment } from 'types/graphql'
import { SmartfieldDOMWidget } from './dom.widget'

export const transformNote = <T extends Note | NoteFieldsFragment | Summary | SummaryFieldsFragment>(obj: T): T => {
  return _transform(obj)
}

export const transformNotes = <
  T extends Note | NoteFieldsFragment | Summary | SummaryFieldsFragment | BulkNoteFieldsFragment,
>(
  arr: T[],
): T[] => {
  return arr.map(_transform)
}

const bypass = false

/**
 * @param note
 * @returns
 */
const _transform = <T extends Note | NoteFieldsFragment | Summary | SummaryFieldsFragment | BulkNoteFieldsFragment>(
  note: T,
): T => {
  if (bypass) return note

  if (hasOldStyle(note.text as string)) {
    note.text = transformHTML(note.text as string)

    try {
      note.content = transformContent(note.content as string)
    } catch (err) {
      note.content = transformNullContent(note.text as string)
    }
  }

  return note
}

/**
 * @param html
 * @returns
 */
const transformHTML = (html: string) => {
  if (hasNewStyle(html) && html.includes('type&quot;:&quot;string')) {
    html = html.replaceAll('type&quot;:&quot;string', 'type&quot;:&quot;text')
  }

  for (const match of html.matchAll(MERFIELD_FIELD_REGEX_GLOBAL)) {
    const newHtml = SmartfieldDOMWidget.createHtml(match[1])
    html = html.replace(match[0], newHtml)
  }

  return html
}

/**
 * @param content
 * @returns
 */
const transformContent = (content: string) => {
  const html = remirrorJSONToHtml(content)
  const transformed = transformHTML(html)

  return parseJSONToRemirror(htmlToRemirrorJSON(transformed))
}

/**
 * @param content
 * @returns
 */
const transformNullContent = (html: string) => {
  const transformed = transformHTML(html)

  return parseJSONToRemirror(htmlToRemirrorJSON(transformed))
}
