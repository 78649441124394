import { Alert, Form } from 'antd'
import Connect from '../connect'

const Recall = (props) => (
  <Connect
    {...props}
    learnMoreLink="https://www.recall.com/"
    baseUrl="https://www.recall.com/"
    key1="dummy-pulse360"
    key2="dummy"
    features={[]}
    renderSettings={(form, submit) => (
      <Form onFinish={submit} form={form}>
        <Alert
          message="Click the 'Connect' button to connect your account"
          type="info"
          showIcon
          // style={{ marginBottom: 16 }}
        />
        <Form.Item name="key1">
          <div />
        </Form.Item>
        <Form.Item name="key2">
          <div />
        </Form.Item>
      </Form>
    )}
  />
)

Recall.defaultProps = {
  baseUrl: 'https://www.hubspot.com/',
  key1: 'dummy-pulse360',
  key2: 'dummy',
}

export default Recall
