/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Form, Input, InputNumber, Space } from 'antd'
import { SmartFieldConfig, SmartFieldIntegrationConfig } from 'components/drawers/Smartfields/types/data'
import { RiskalyzeConfig, RiskalyzeQuestionaireConfig } from 'components/drawers/Smartfields/types/integration'
import { FormItemLabel } from '../../../../../smartfield.styles'
import { ProviderSmartFields, ProviderSmartFieldsProps } from '../../../provider.smartfields'
import { useRiskalyzeQuestionaireData } from '../../riskalyze.hooks'
import { RiskalyzeClients } from '../riskalyze.clients'
import { useEffect } from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import styles from 'styles'

interface RiskalyzeQuestionaireSmartFieldsProps extends ProviderSmartFieldsProps {
  config: SmartFieldConfig<SmartFieldIntegrationConfig<RiskalyzeConfig>>
}

export const RiskalyzeQuestionaireSmartFields: React.FC<RiskalyzeQuestionaireSmartFieldsProps> = ({
  form,
  config,
  uid,
  setAsyncLoading,
}) => {
  const questionaireConfig = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<RiskalyzeConfig<RiskalyzeQuestionaireConfig>>
  >
  const selectedContact = Form.useWatch('questionaire_contact', form)
  const { clients, error, onSelectClient, onSelectInvested, onGenerateLink, generatingLink } =
    useRiskalyzeQuestionaireData(questionaireConfig, form, uid)

  const disableGenerateButton =
    Boolean(form.getFieldValue('questionaire_link')) || !Boolean(form.getFieldValue('questionaire_contact'))
  const isLinkCreated = Boolean(form.getFieldValue('questionaire_link'))

  useEffect(() => {
    if (setAsyncLoading) {
      setAsyncLoading(generatingLink)
    }
  }, [generatingLink, setAsyncLoading])

  return (
    <ProviderSmartFields
      form={form}
      uid={uid}
      error={error}
      // label={config?.label}
      data-testid="replace-smartfield-date"
      showOnError
      allowOverride={false}
    >
      <RiskalyzeClients
        form={form}
        formName="questionaire_contact"
        clients={clients}
        loading={false}
        onChange={onSelectClient}
      />

      <Form.Item
        name="questionaire_invested"
        rules={[{ required: true, message: 'Please enter a value' }]}
        label={<FormItemLabel label="Amount Invested (optional)" description="Select the amount invested" />}
      >
        <InputNumber onChange={onSelectInvested} size="large" disabled={!selectedContact} style={{ width: 160 }} />
      </Form.Item>

      <Form.Item
        name="questionaire_link_title"
        rules={[{ required: false, message: 'Title for the url' }]}
        label={<FormItemLabel label="Link Title (optional)" description="Add a title to the questionnaire url" />}
      >
        <Input size="large" disabled={!selectedContact} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="questionaire_link" hidden>
        <Input />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button onClick={onGenerateLink} disabled={generatingLink || disableGenerateButton} loading={generatingLink}>
            Generate Link
          </Button>
          {isLinkCreated && <CheckCircleOutlined style={{ color: styles.colors.primary }} />}
        </Space>
      </Form.Item>
    </ProviderSmartFields>
  )
}
