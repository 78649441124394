import { Form, Input } from 'antd'
import Connect from '../connect'

const RedtailConnect = (props) => (
  <Connect
    learnMoreLink="https://corporate.redtailtechnology.com/crm/"
    {...props}
    features={['Sync Contacts', 'Sync Events']}
    renderSettings={(form, submit) => (
      <Form onFinish={submit} form={form}>
        <Form.Item label="Username" name="key2" rules={[{ required: true, message: 'Enter username!' }]}>
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Password" name="key3" rules={[{ required: true, message: 'Enter password!' }]}>
          <Input size="large" type="password" />
        </Form.Item>
      </Form>
    )}
  />
)

RedtailConnect.defaultProps = {
  key1: '',
}

export default RedtailConnect
