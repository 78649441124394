import { CloseCircleOutlined } from '@ant-design/icons'
import { Alert, Select, Table } from 'antd'
import SelectAdvisorReplacerModal from 'components/base/SelectAdvisorReplacerModal'
import { useEffect, useState } from 'react'
import { msg } from 'stores/msg'
import styled from 'styled-components'
import styles from 'styles'
import { captureExceptionSilently } from '../../helpers/sentry'

const StyledTable = styled(Table)`
  border: 0 !important;
  .ant-table-small {
    border: 0 !important;
  }
  .ant-table-tbody > tr.ant-table-row-selected td {
    background: ${styles.colors.selectedBackground};
  }
  .ant-table-selection-column {
    display: none;
    /* .ant-select-selection__placeholder {
      display
    } */
  }
`

const TableCell = styled.div`
  cursor: default;
  font-size: 13px;
  font-weight: normal;
`

const CRMUsersSelect = ({ crmUsers, allCrmUsers, onChange, advisor, loading }) => {
  const selectedCrmUser = allCrmUsers.find(({ id }) => String(id) === String(advisor.externalId))
  return (
    <Select
      loading={loading}
      showSearch
      allowClear={!!advisor.externalId}
      clearIcon={
        <CloseCircleOutlined style={{ fontSize: 16, marginTop: -2, marginLeft: -2, color: styles.colors.error }} />
      }
      style={{ width: 200 }}
      onChange={(e) => onChange(advisor, e)}
      onClear={(e) => onChange(advisor, null)}
      placeholder="Select a person"
      optionFilterProp="children"
      defaultValue={advisor.externalId || null}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {[...crmUsers, selectedCrmUser]
        .filter((user) => Boolean(user))
        .map(({ id, fullName }) => (
          <Select.Option key={id} value={String(id)} title={fullName}>
            {fullName}
          </Select.Option>
        ))}
    </Select>
  )
}

const advisorsWithFullName = () =>
  global.data.advisors.filteredAdvisors().map((advisor) => ({
    ...advisor,
    fullName: global.data.advisors.getFullName(advisor),
    externalId: advisor.externalId ? advisor.externalId : null,
  }))

const filterCrmUsers = (users, advisors) =>
  users.filter(({ id }) => !advisors.some(({ externalId }) => String(id) === String(externalId)))

const ConfigureActiveIntegration = (props) => {
  const [advisors, setAdvisors] = useState(advisorsWithFullName())
  const [showSelectReplacer, setShowSelectReplacer] = useState(null)
  const [allCrmUsers, setAllCrmUsers] = useState([])
  const [filteredCrmUsers, setFilteredCrmUsers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchCrmUsers() {
      try {
        const users = await global.data.advisors.fetchCRMUsers()
        setAllCrmUsers(users)
        setFilteredCrmUsers(filterCrmUsers(users, advisors))
      } catch (err) {
        captureExceptionSilently(err, { message: 'fetchCrmUsers' })
        msg.error(err.message)
      } finally {
        setLoading(false)
      }
    }
    fetchCrmUsers()
  }, [advisors])

  const updateAdvisorsCrmUser = async (advisor, externalId = null, replacer) => {
    setLoading(true)
    try {
      const idUpdated = await global.data.advisors.update({ externalId }, advisor.id, replacer?.id)
      if (idUpdated) {
        const newAdvisors = advisorsWithFullName()
        const newUsers = filterCrmUsers(allCrmUsers, newAdvisors)
        setAdvisors(newAdvisors)
        setFilteredCrmUsers(newUsers)
        msg.success(`Advisor successfully ${externalId ? 'linked to' : 'unlinked from'} the CRM user!`)
      }
    } catch (err) {
      if (err.name === 'advisor_has_related_models_error') {
        setShowSelectReplacer({ replaced: advisor, action: 'unlink' })
      } else {
        captureExceptionSilently(err, { message: 'updateAdvisorsCrmUser', data: { advisorId: advisor.id, externalId } })
        msg.error(`Error updating the CRM user`, err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {showSelectReplacer && (
        <SelectAdvisorReplacerModal
          replaced={showSelectReplacer.replaced}
          action={showSelectReplacer.action}
          onSubmit={(user, replacer) => updateAdvisorsCrmUser(user, null, replacer)}
          onClose={() => setShowSelectReplacer(false)}
        />
      )}
      <Alert
        style={{ marginBottom: 20 }}
        message="Match Pulse360 Users with your CRM users"
        description="This will allow us, among other things, to assign tasks and workflows to your CRM users!"
        type="info"
        showIcon
        banner
      />
      <StyledTable
        style={{ overflowY: 'auto' }}
        dataSource={advisors}
        rowKey={(f) => f.id}
        pagination={false}
        bordered={false}
        size="small"
      >
        <Table.Column
          title="Pulse360 Advisor"
          dataIndex="fullName"
          key="id"
          render={(v, r) => (
            <TableCell>
              <span style={{ color: styles.colors.lightBlack }}>{v}</span>{' '}
              <span style={{ color: styles.colors.extraLightBlack }}>({r.email})</span>
            </TableCell>
          )}
          sorter={(a, b) => String(a.fullName).localeCompare(String(b.fullName), 'en', { sensitivity: 'base' })}
        />

        <Table.Column
          dataIndex="externalId"
          align="right"
          title="CRM User"
          width={200}
          render={(_, r) => (
            <CRMUsersSelect
              crmUsers={filteredCrmUsers}
              allCrmUsers={allCrmUsers}
              advisor={r}
              onChange={updateAdvisorsCrmUser}
              loading={loading}
            />
          )}
        />
      </StyledTable>
    </>
  )
}

export default ConfigureActiveIntegration
