import { SmartFieldConfig, SmartFieldIntegrationConfig } from '../types/data'
import { SmartFieldFormValues, SmartFieldIntegrationFormValues } from '../types/form'
import { holistiplanConfigParser, holistiplanParser } from './providers/holistiplan/parser'
import { riskalyzeConfigParser, riskalyzeParser } from './providers/riskalyze/parser'

export const integrationFormParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldConfig => {
  const intgrValues = values as SmartFieldFormValues<SmartFieldIntegrationFormValues>

  config.provider = intgrValues.provider

  switch (intgrValues.provider) {
    case 'holistiplan':
      config = holistiplanParser(values, config)
      break
    case 'riskalyze':
      config = riskalyzeParser(values, config)
      break
  }

  return config as SmartFieldConfig<SmartFieldIntegrationConfig>
}

export const integrationConfigParser = (config: SmartFieldConfig): SmartFieldIntegrationFormValues => {
  const cfg = config as SmartFieldConfig<SmartFieldIntegrationConfig>

  const values: SmartFieldIntegrationConfig = {
    provider: cfg.provider,
  }

  switch (cfg.provider) {
    case 'holistiplan':
      holistiplanConfigParser(values, cfg)
      break
    case 'riskalyze':
      riskalyzeConfigParser(values, cfg)
      break
  }

  return values
}
